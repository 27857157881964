import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { NavLink, useSearchParams } from "react-router-dom";

import { handleVerifyEmail } from "../../../redux/actions/authActions";

import "../../css/Auth.css";
import WelcomeCard from "./WelcomeCard";
import Spinner from "../../components/Spinner";
import ReduxErrorMessage from "../../components/ReduxErrorMessage";
import AuthPage from "./AuthPage";
import artworkHiRes from "../../assets/images/artwork-left-hi-res.png";
import { useEffect } from "react";
import { hasActionSucceeded } from "../../../selectors/successfulActions";

const { isActionInflight } = require("../../../selectors/inflightActions");

const ACTION_KEY = "VERIFY_EMAIL";

const VerifyEmail = (props: {
  isLoading: boolean,
  isActionSuccessful: boolean,
  handleVerifyEmail: (oobCode: string) => void,
}) => {
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get("oobCode");
  const handleVerifyEmail = props.handleVerifyEmail;
  useEffect(() => {
    if (oobCode) {
      handleVerifyEmail(oobCode);
    }
  }, [oobCode, handleVerifyEmail]);

  return (
    <AuthPage artwork={artworkHiRes}>
      <div className="form-holder">
        <WelcomeCard welcomeText="Verifying email... " />
        {props.isActionSuccessful ? (
          <SuccessMessage />
        ) : props.isLoading ? (
          <div>
            Verifying email...&nbsp;
            <Spinner actionKey={ACTION_KEY} />
          </div>
        ) : null}
        <ReduxErrorMessage actionKey={ACTION_KEY} />
      </div>
    </AuthPage>
  );
};

const SuccessMessage = () => {
  return (
    <div className="w-100">
      <p className="text-success">
        Email successfully verified. Login to continue{" "}
      </p>
      <NavLink to="/auth/signin" className="btn btn-primary brea-button">
        Login
      </NavLink>
    </div>
  );
};

const matchStateToProps = (state) => ({
  isLoading: isActionInflight(state, ACTION_KEY),
  isActionSuccessful: hasActionSucceeded(state, ACTION_KEY),
});

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleVerifyEmail,
    },
    dispatch
  );
};

export default connect(matchStateToProps, matchDispatchToProps)(VerifyEmail);
