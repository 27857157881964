import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { userSignedInSelector } from "../../selectors/user";

import { Navbar as BootstrapNavbar, Nav } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";

import Logo from "../assets/images/logo.png";
import { Link, NavLink } from "react-router-dom";
import "../css/Navbar.css";

const NavbarAdmin = (props) => {
  if (!props.viewerSignedIn) {
    return null;
  }
  return (
    <BootstrapNavbar
      expand="sm"
      className="main-nav-holder w-100 justify-content-between"
      style={{ fontFamily: "Poppins" }}
    >
      <BootstrapNavbar.Brand>
        <Link className="nav-link" to="/">
          <img src={Logo} className="logo" alt="Jhotika logo" />
        </Link>
      </BootstrapNavbar.Brand>

      <div className="nav-wrapper">
        <BootstrapNavbar.Toggle aria-controls="basic-BootstrapNavbar-nav" />
        <BootstrapNavbar.Collapse
          id="basic-BootstrapNavbar-nav "
          className="justify-content-end"
        >
          <Nav className="mr-auto">
            <Nav.Item>
              <NavLink className="nav-link" to="/admin">
                Admin Home
              </NavLink>
            </Nav.Item>

            <NavDropdown title="Account" align={"end"}>
              <NavDropdown.Item href="/auth/signin">
                <Nav.Item
                  onClick={() => {
                    props.handleSignOutRequest();
                  }}
                >
                  Sign Out
                </Nav.Item>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </BootstrapNavbar.Collapse>
      </div>
    </BootstrapNavbar>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

function mapStateToProps(state) {
  return {
    viewerSignedIn: userSignedInSelector(state),
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(NavbarAdmin);
