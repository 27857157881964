import { getKey } from "../redux/actions/actionUtils";

export const failedActionsSelector = (state) =>
  Object.keys(state)?.failedActions;

export const hasActionFailed = (state, action) => {
  const actionKey = getKey(action);
  return Object.keys(state?.failedActions ?? {}).includes(actionKey);
};

export const getError = (state, action) => {
  const actionKey = getKey(action);
  return state?.failedActions?.[actionKey];
};
