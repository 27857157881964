export const baseUrl = "https://brea-394006.uk.r.appspot.com";
// export const baseUrl = "http://localhost:8080";

export const formatError = (error) => {
  if (process.env.NODE_ENV === "development") {
    printError(error);
  }

  if (error.response) {
    return error.response.data.message;
  } else if (error.request) {
    return "Couldn't complete operation. Please try again later.";
  }
  return error.message;
};

export const printError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log("error.response:: ");
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log("error.request: ");
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("every other error: ");
    console.log("Error", error.message);
  }
  console.log(error);
};

export const validateEmail = (email: string): boolean => {
  return (
    email &&
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) != null
  );
};

export const validateFullName = (name: string | null): Boolean => {
  if (!name || !typeof name === "string") {
    return false;
  }
  name = name.trim();
  return String(name).match(/^[a-zA-Z]+ [a-zA-Z]+$/) != null;
};

export const validateFullNameMessage = (name: string | null): string => {
  const ret =
    name && validateFullName(name)
      ? null
      : 'Please enter your full name in "Firstname Lastname" format. e.g., Michael Jackson';
  return ret;
};

export const validatePassword = (password: string): Boolean => {
  return String(password).length >= 6;
};

export const genUrl = (path: string): string => {
  var resultUrl = new URL(path, baseUrl).href;
  return resultUrl;
};
