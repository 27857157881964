import { useEffect, useState } from "react";

import { connect } from "react-redux";

import { getError } from "../../selectors/failedActions";

const ReduxErrorMessage = (props: {
  actionKey: string,
  state: object,
  extraErrorMessage: string,
}) => {
  const [error, setError] = useState(null);
  const { actionKey, state } = props;

  useEffect(() => {
    const error = getError(state, actionKey) ?? props.extraErrorMessage;
    if (error) {
      setError(error);
    } else {
      setError(null);
    }
  }, [actionKey, setError, state, props.extraErrorMessage]);
  return error ? (
    <p>
      <span className="text-danger">{error}</span>
    </p>
  ) : null;
};

const matchStateToProps = (state) => ({
  state: state,
});

export default connect(matchStateToProps, null)(ReduxErrorMessage);
