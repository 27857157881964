import { combineReducers } from "redux";
import { authActions } from "../actions/authActions";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import profileReducers from "./profileReducers";
import inflightActionsReducers from "./inflightActionsReducers";
import failedActions from "./failedActionsReducers";
import successfulActionsReducers from "./successfulActionsReducers";

const authPersistConfig = {
  key: "auth",
  storage: storage,
};

const appReducer = combineReducers({
  profile: persistReducer(authPersistConfig, profileReducers),
  inflightActions: inflightActionsReducers,
  failedActions: failedActions,
  successfulActions: successfulActionsReducers,
});

const rootReducer = (state, action) => {
  if (
    action.type === authActions.SIGNOUT_SUCCESSFUL ||
    action.type === authActions.DELETE_ACCOUNT_SUCCESSFUL
  ) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
