import React, { useState } from "react";

import { useLoaderData, Await } from "react-router-dom";
import "../../css/Profile.css";
import icUploadDP from "../../assets/images/ic-upload-dp.png";
import UploadDP from "../../assets/images/userAvatar.png";
import { NotFoundCore } from "../misc/NotFound";
import FullPageSpinner from "../misc/FullPageSpinner";

import BioModal from "./BioModal";
import PasswordModal from "./PasswordModal";
import EmailModal from "./EmailModal";

import NameModalContainer from "./NameModal";
import ProfilePicModal from "./ProfilePicModal";
import CurrentCityModal from "./CurrentCityModal";
import DeleteAccountModal from "./DeleteAccountModal";
import UsernameModal from "./UsernameModal";

const ProfileSettingsContainer = (props) => {
  const [showBioModal, setShowBioModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showNameModal, setShowNameModal] = useState(false);
  const [showProfilePicModal, setShowProfilePicModal] = useState(false);
  const [showCurrentCityModal, setShowCurrentCityModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showUsernameModal, setShowUsernameModal] = useState(false);

  const data = useLoaderData();
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <Await resolve={data.userDataPromises} errorElement={<NotFoundCore />}>
        {([userData, status]) => {
          return (
            <>
              <ProfileSettings
                userData={userData}
                setShowBioModal={setShowBioModal}
                setShowEmailModal={setShowEmailModal}
                setShowNameModal={setShowNameModal}
                setShowPasswordModal={setShowPasswordModal}
                setShowProfilePicModal={setShowProfilePicModal}
                setShowCurrentCityModal={setShowCurrentCityModal}
                setShowDeleteAccountModal={setShowDeleteAccountModal}
                setShowUsernameModal={setShowUsernameModal}
              />
              <BioModal
                userData={userData}
                show={showBioModal}
                onHide={() => {
                  setShowBioModal(false);
                }}
              />
              <PasswordModal
                userData={userData}
                show={showPasswordModal}
                onHide={() => {
                  setShowPasswordModal(false);
                }}
              />
              <EmailModal
                show={showEmailModal}
                onHide={() => {
                  setShowEmailModal(false);
                }}
              />
              <NameModalContainer
                userData={userData}
                show={showNameModal}
                onHide={() => {
                  setShowNameModal(false);
                }}
              />
              <ProfilePicModal
                userData={userData}
                show={showProfilePicModal}
                onHide={() => {
                  setShowProfilePicModal(false);
                }}
              />
              <CurrentCityModal
                userData={userData}
                show={showCurrentCityModal}
                onHide={() => {
                  setShowCurrentCityModal(false);
                }}
              />
              <DeleteAccountModal
                show={showDeleteAccountModal}
                onHide={() => {
                  setShowDeleteAccountModal(false);
                }}
              />

              <UsernameModal
                userData={userData}
                show={showUsernameModal}
                onHide={() => {
                  setShowUsernameModal(false);
                }}
              />
            </>
          );
        }}
      </Await>
    </React.Suspense>
  );
};

const ProfileSettings = (props) => {
  if (props.userData == null) {
    return null;
  }

  return (
    <div className="profile-container mx-5">
      <h5 className="mt-3">Profile Setting</h5>
      <div className="dpName d-flex w-100 mt-3">
        <div className="dpUpdate">
          <img
            className=""
            style={{ height: "9rem", borderRadius: "50%" }}
            src={props?.userData?.profileImageUrl || UploadDP}
            alt="Profile"
          />
          <div
            className="d-flex flex-column "
            style={{
              marginTop: "-62px",
              marginLeft: "140px",
              marginRight: "3rem",
            }}
          >
            <img
              alt="Profile"
              src={icUploadDP}
              style={{ height: "3rem", width: "3rem" }}
              onClick={() => {
                props.setShowProfilePicModal(true);
              }}
            />
          </div>
        </div>

        <div className="nameEmail w-100">
          <input
            className="brea-input settingInput user-profile"
            placeholder="Full Name"
            readOnly
            type="text"
            value={props.userData.fullName}
            onChange={() => props.setShowNameModal(true)}
            onClick={() => props.setShowNameModal(true)}
          />

          <input
            className="brea-input settingInput user-profile"
            id="username"
            name="username"
            placeholder="Username"
            readOnly
            type="text"
            value={props.userData.username}
            onClick={() => props.setShowUsernameModal(true)}
          />
          <input
            className="brea-input settingInput user-profile"
            id="email"
            name="email"
            placeholder="Email"
            readOnly
            type="email"
            value={props.userData.email}
            onClick={() => props.setShowEmailModal(true)}
          />
        </div>
      </div>

      <div className="nameEmail w-80">
        <h5>Current City</h5>
        <input
          className="brea-input settingInput user-profile"
          id="current-city"
          name="current-city"
          placeholder="Current City"
          readOnly
          value={props.userData.currentCity}
          onClick={() => props.setShowCurrentCityModal(true)}
        />
      </div>
      <div className="mt-4 w-100">
        <h5>Bio</h5>
        <textarea
          className="brea-input settingInput user-profile"
          id="bio"
          name="bio"
          placeholder="Bio"
          readOnly
          value={props.userData.bio}
          onClick={() => props.setShowBioModal(true)}
        />
      </div>
      <div className="mt-4 w-100">
        <button
          className="mt-3 outline-button"
          onClick={() => {
            props.setShowPasswordModal(true);
          }}
        >
          Change Password
        </button>
      </div>
      <div className="mt-4 w-100">
        <button
          className="mt-3 outline-button-deletion"
          onClick={() => {
            props.setShowDeleteAccountModal(true);
          }}
        >
          Delete Account
        </button>
      </div>
    </div>
  );
};

export default ProfileSettingsContainer;
