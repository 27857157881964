import { actionActions } from "../actions/actionActions";
import { getFailed, getKey, getNonFailed } from "../actions/actionUtils";

export const DEFAULT_REDUCER = {};

const failedActionsReducers = (state = DEFAULT_REDUCER, action) => {
  const type = action.type;
  if (action.type === actionActions.CLEAR_ALL_ACTIONS_REQUESTED) {
    const tmpState = { ...state };
    return Object.keys(tmpState).filter(
      (item) => action.payload.actionKey !== item
    );
  } else if (getNonFailed(type)) {
    return Object.keys(state).filter((item) => getKey(type) !== item);
  } else if (getFailed(type)) {
    const tmpState = { ...state };
    const actionKey = getKey(type);
    tmpState[actionKey] =
      action.payload?.error?.message ??
      action.payload?.error ??
      action.payload ??
      null;
    return tmpState;
  }
  return state;
};

export default failedActionsReducers;
