export const RequestTypes = {
  REQUESTED: "REQUESTED",
  SUCCESSFUL: "SUCCESSFUL",
  FAILED: "FAILED",
};

export const getKey = (type) => {
  return Object.keys(RequestTypes).reduce(
    (acc, s) => acc.replace("_" + s, ""),
    type
  );
};

export const getRequestType = (type) => type.split("_").pop();

export const getDone = (type) =>
  ["SUCCESSFUL", "FAILED"].includes(getRequestType(type));

export const getNonFailed = (type) =>
  [RequestTypes.REQUESTED, RequestTypes.SUCCESSFUL].includes(
    getRequestType(type)
  );

export const getNonSuccessful = (type) =>
  [RequestTypes.REQUESTED, RequestTypes.FAILED].includes(getRequestType(type));

export const getLoading = (type) =>
  getRequestType(type) === RequestTypes.REQUESTED;

export const getFailed = (type) => getRequestType(type) === RequestTypes.FAILED;

export const getSuccessful = (type) =>
  getRequestType(type) === RequestTypes.SUCCESSFUL;

export const buildType = (actionType, requestType) => {
  return actionType + "_" + requestType;
};
