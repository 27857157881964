import { useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { handleForgotPasswordSubmit } from "../../../redux/actions/authActions";

import "../../css/Auth.css";
import WelcomeCard from "./WelcomeCard";
import SocialLogin from "./SocialLogin";
import { NavLink } from "react-router-dom";

import AuthPage from "./AuthPage";
import forgotPasswordArtworkHiRes from "../../assets/images/forgot-password-hi-res.png";
import Spinner from "../../components/Spinner";
import { isActionInflight } from "../../../selectors/inflightActions";
import { hasActionSucceeded } from "../../../selectors/successfulActions";
import ReduxErrorMessage from "../../components/ReduxErrorMessage";
import { handleClearAllActionsOfType } from "../../../redux/actions/actionActions";

const ACTION_KEY = "RESET_PASSWORD_EMAIL";

type Props = {
  hasEmailBeenSent: boolean,
  isLoading: boolean,
  handleForgotPasswordSubmit: (email: string) => {},
  clearAllActions: () => {},
};

const ForgotPassword = (props: Props) => {
  return (
    <AuthPage artwork={forgotPasswordArtworkHiRes}>
      <div className="form-holder">
        <WelcomeCard welcomeText="Reset password for" />
        {props.hasEmailBeenSent ? (
          <SuccessMessage />
        ) : (
          <ForgotPasswordForm {...props} />
        )}
      </div>
    </AuthPage>
  );
};

const ForgotPasswordForm = (props: {
  isLoading: boolean,
  clearAllActions: () => void,
  handleForgotPasswordSubmit: (email: string) => void,
}) => {
  const [email, setEmail] = useState("");
  return (
    <>
      <p className="subText text-md-start text-center">
        <span>
          They say elephants never forget, <br />
        </span>
        <span>but we're here to remind you it's okay to be human!</span>
      </p>
      <hr className="line-hr m-3" />
      <form>
        <input
          type="text"
          name="email"
          id="email"
          disabled={props.isLoading}
          placeholder="Enter your email"
          className="brea-input"
          onChange={(e) => {
            setEmail(e.target.value);
            props.clearAllActions();
          }}
        />
        <ReduxErrorMessage actionKey={ACTION_KEY} />
        <button
          type="submit"
          className="mt-3 btn btn-primary brea-button"
          disabled={props.isLoading}
          onClick={(e) => {
            props.handleForgotPasswordSubmit(email);
          }}
        >
          <Spinner actionKey={ACTION_KEY} /> Send password reset email
        </button>
      </form>
      <hr className="line-hr mt-3" />
      {/* <p
        style={{
          textAlign: "center",
          color: "#a2a2a2",
          fontWeight: "regular",
        }}
      >
        or
      </p>
      <SocialLogin /> */}
      <p
        className="text-center pt-4"
        style={{
          fontSize: ".9rem",
        }}
      >
        I know my password.{" "}
        <span
          style={{
            color: "#356AFC",
            textDecoration: "underline",
          }}
        >
          <NavLink to="/auth/signin"> Log In</NavLink>
        </span>
      </p>
    </>
  );
};

const SuccessMessage = () => {
  return (
    <p>
      <span className="text-success">
        We have sent you an email with instructions to reset your password.
      </span>
      <br />
      <span className="small">
        If you don't see the email, please check your spam folder.
      </span>
    </p>
  );
};

const matchStateToProps = (state) => ({
  isLoading: isActionInflight(state, ACTION_KEY),
  hasEmailBeenSent: hasActionSucceeded(state, ACTION_KEY),
});

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleForgotPasswordSubmit,
      clearAllActions: () => handleClearAllActionsOfType(ACTION_KEY),
    },
    dispatch
  );
};

export default connect(matchStateToProps, matchDispatchToProps)(ForgotPassword);
