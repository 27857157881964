import { NavLink } from "react-router-dom";

const ForgotPasswordCta = () => {
  return (
    <p
      className="text-center pt-4"
      style={{
        fontSize: ".9rem",
      }}
    >
      <span
        style={{
          color: "#356AFC",
          textDecoration: "underline",
        }}
      >
        <NavLink to="/auth/forgotPassword"> Forgot Password?</NavLink>
      </span>
    </p>
  );
};

export default ForgotPasswordCta;
