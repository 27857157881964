export const profileActions = {
  FETCH_PROFILE_REQUESTED: "FETCH_PROFILE_REQUESTED",
  FETCH_PROFILE_SUCCESSFUL: "FETCH_PROFILE_SUCCESSFUL",
  FETCH_PROFILE_FAILED: "FETCH_PROFILE_FAILED",

  SIGNUP_REQUESTED: "SIGNUP_REQUESTED",
  SIGNUP_SUCCESSFUL: "SIGNUP_SUCCESSFUL",
  SIGNUP_FAILED: "SIGNUP_FAILED",

  SIGNIN_REQUESTED: "SIGNIN_REQUESTED",
  SIGNIN_SUCCESSFUL: "SIGNIN_SUCCESSFUL",
  SIGNIN_FAILED: "SIGNIN_FAILED",

  SIGNOUT_REQUESTED: "SIGNOUT_REQUESTED",
  SIGNOUT_FAILED: "SIGNOUT_FAILED",
  SIGNOUT_SUCCESSFUL: "SIGNOUT_SUCCESSFUL",

  SIGNIN_WITH_GOOGLE_REQUESTED: "SIGNIN_WITH_GOOGLE_REQUESTED",

  UPDATE_NAME_REQUESTED: "UPDATE_NAME_REQUESTED",
  UPDATE_NAME_SUCCESSFUL: "UPDATE_NAME_SUCCESSFUL",
  UPDATE_NAME_FAILED: "UPDATE_NAME_FAILED",

  UPDATE_BIO_REQUESTED: "UPDATE_BIO_REQUESTED",
  UPDATE_BIO_SUCCESSFUL: "UPDATE_BIO_SUCCESSFUL",
  UPDATE_BIO_FAILED: "UPDATE_BIO_FAILED",

  UPDATE_EMAIL_REQUESTED: "UPDATE_EMAIL_REQUESTED",
  UPDATE_EMAIL_SUCCESSFUL: "UPDATE_EMAIL_SUCCESSFUL",
  UPDATE_EMAIL_FAILED: "UPDATE_EMAIL_FAILED",

  UPDATE_PROFILE_IMAGE_REQUESTED: "UPDATE_PROFILE_IMAGE_REQUESTED",
  UPDATE_PROFILE_IMAGE_SUCCESSFUL: "UPDATE_PROFILE_IMAGE_SUCCESSFUL",
  UPDATE_PROFILE_IMAGE_FAILED: "UPDATE_PROFILE_IMAGE_FAILED",

  UPDATE_STATUS_REQUESTED: "UPDATE_STATUS_REQUESTED",
  UPDATE_STATUS_SUCCESSFUL: "UPDATE_STATUS_SUCCESSFUL",
  UPDATE_STATUS_FAILED: "UPDATE_STATUS_FAILED",

  UPDATE_CURRENT_CITY_REQUESTED: "UPDATE_CURRENT_CITY_REQUESTED",
  UPDATE_CURRENT_CITY_SUCCESSFUL: "UPDATE_CURRENT_CITY_SUCCESSFUL",
  UPDATE_CURRENT_CITY_FAILED: "UPDATE_CURRENT_CITY_FAILED",

  DELETE_ACCOUNT_REQUESTED: "DELETE_ACCOUNT_REQUESTED",
  DELETE_ACCOUNT_SUCCESSFUL: "DELETE_ACCOUNT_SUCCESSFUL",
  DELETE_ACCOUNT_FAILED: "DELETE_ACCOUNT_FAILED",

  UPDATE_USERNAME_REQUESTED: "UPDATE_USERNAME_REQUESTED",
  UPDATE_USERNAME_SUCCESSFUL: "UPDATE_USERNAME_SUCCESSFUL",
  UPDATE_USERNAME_FAILED: "UPDATE_USERNAME_FAILED",
};

export function handleFetchProfile(userUuid) {
  return {
    type: profileActions.FETCH_PROFILE_REQUESTED,
    payload: {
      userUuid: userUuid,
    },
  };
}

export function handleUpdateBioRequest(bio) {
  return {
    type: profileActions.UPDATE_BIO_REQUESTED,
    payload: {
      bio: bio,
    },
  };
}

export function handleUpdateUsername(username) {
  return {
    type: profileActions.UPDATE_USERNAME_REQUESTED,
    payload: {
      username: username,
    },
  };
}

export function handleUpdateUserFullname(name) {
  return {
    type: profileActions.UPDATE_NAME_REQUESTED,
    payload: {
      name: name,
    },
  };
}

export function handleUpdateEmailRequest(newEmail, password) {
  return {
    type: profileActions.UPDATE_EMAIL_REQUESTED,
    payload: {
      newEmail,
      password,
    },
  };
}

export function handleUpdateProfileImageRequest(imageFileUrl) {
  return {
    type: profileActions.UPDATE_PROFILE_IMAGE_REQUESTED,
    payload: {
      imageFileUrl,
    },
  };
}

export function handleUpdateStatusRequest(note, availability, title) {
  return {
    type: profileActions.UPDATE_STATUS_REQUESTED,
    payload: {
      note,
      availability,
      title,
    },
  };
}

export function handleUpdateCurrentCity(currentCity) {
  return {
    type: profileActions.UPDATE_CURRENT_CITY_REQUESTED,
    payload: {
      currentCity,
    },
  };
}

export function handleDeleteAccount(email, password) {
  return {
    type: profileActions.DELETE_ACCOUNT_REQUESTED,
    payload: {
      email,
      password,
    },
  };
}
