import React, { useState } from "react";
import "../css/ChatSigned.css";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { userDataSelector } from "../../selectors/user";

import jhot from "../assets/images/jhot-avatar.jpg";
import icsend from "../assets/images/ic-send.png";

const ChatSigned = (props: { userData: { displayName: string | null } }) => {
  // TODO(Taman): Fetch user's name from redux store
  const userName = props?.userData?.displayName ?? "there";
  const JHOT = "Jhot";
  const [chatHistory, setChatHistory] = useState([
    {
      sender: JHOT,
      message: `Hello ${userName}, how can help you today?`,
    },
  ]);

  return (
    <div className="d-flex flex-row">
      <QuestionSuggestionSection />
      <div className="chat holder mx-4">
        <div className="jhot-holder d-flex p-4">
          <img src={jhot} className="me-2 jhot-avatar-chat" alt="Jhot" />
          <div className="jhot-name">
            <p
              style={{
                color: "#3369FF",
                fontSize: "1.3rem",
                marginBottom: "-5px",
              }}
            >
              <b>{JHOT}</b>
            </p>
            <small className="online" style={{ color: "#3ABF38" }}>
              <span className="dot"></span>
              <b> Online</b>
            </small>
          </div>
        </div>

        <hr style={{ color: "#626262", marginTop: "0px" }} />

        <div
          className="conversation d-flex flex-column"
          style={{ height: "25rem" }}
        >
          {chatHistory.map((chat, id) => (
            <div key={id}>
              {chat.sender === JHOT ? (
                <JhotReply message={chat.message} />
              ) : (
                <UserReply message={chat.message} />
              )}
            </div>
          ))}
        </div>

        <Input setChatHistory={setChatHistory} />
      </div>
    </div>
  );
};

const QuestionSuggestionSection = () => {
  return (
    <div className="suggestion holder ms-4 p-3 text-muted ">
      What to ask Jhot?
      <button className="qtojhot">
        What are some popular travel destinations right now?
      </button>
    </div>
  );
};

const Input = (props: {
  setChatHistory: React.Dispatch<React.SetStateAction<never[]>>,
}) => {
  const [message, setMessage] = useState("");
  return (
    <div className="mx-4 mt-auto py-3 typing-input">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.setChatHistory((prev) => [
            ...prev,
            {
              sender: "user",
              message,
            },
          ]);
          setMessage("");
        }}
      >
        <input
          className="py-3 px-3 input-style"
          type="text"
          name=""
          id=""
          placeholder="Write your message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <button id="send-button" type="submit">
          <img src={icsend} alt="Jhot" />
        </button>
      </form>
    </div>
  );
};

const JhotReply = (props: { message: string }) => {
  return (
    <div className="jhot-reply d-flex my-2">
      <img
        src={jhot}
        style={{ height: "2.8rem" }}
        className="align-self-end ms-4  me-2 jhot-avatar-chat"
        alt="Jhot"
      />
      <p className="jhot-text">{props.message}</p>
    </div>
  );
};

const UserReply = (props: { message: string }) => {
  return (
    <div className="user-reply d-flex" style={{ marginTop: 4 }}>
      <p className="user-text me-4">{props.message}</p>
    </div>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

function mapStateToProps(state) {
  return {
    userData: userDataSelector(state),
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(ChatSigned);
