import loadgingGIF from "../../assets/gif/loading.gif";

const FullPageSpinner = () => {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-content-center mt-5 pt-5"
    >
      <img src={loadgingGIF} alt="Loading..." />
    </div>
  );
};

export default FullPageSpinner;
