import { bindActionCreators } from "redux";
import {
  handleFacebookLogin,
  handleGoogleLogin,
  handleAppleLogin,
} from "../../../redux/actions/authActions";
import { connect } from "react-redux";

import fb from "../../assets/images/fb.png";
import google from "../../assets/images/google.png";
import apple from "../../assets/images/apple.png";

const SocialLogin = (props) => {
  return (
    <div className="text-center">
      <input
        type="image"
        src={fb}
        className="social-auth"
        alt="Jhotika"
        onClick={() => props.handleFacebookLogin()}
      />
      <input
        type="image"
        src={google}
        className="social-auth mx-4"
        alt="Jhotika"
        onClick={() => props.handleGoogleLogin()}
      />
      <input
        type="image"
        src={apple}
        className="social-auth"
        alt="Jhotika"
        onClick={() => props.handleAppleLogin()}
      />
    </div>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleFacebookLogin,
      handleGoogleLogin,
      handleAppleLogin,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, matchDispatchToProps)(SocialLogin);
