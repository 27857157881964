export const authActions = {
  RESET_PASSWORD_EMAIL_REQUESTED: "RESET_PASSWORD_EMAIL_REQUESTED",
  RESET_PASSWORD_EMAIL_SUCCESSFUL: "RESET_PASSWORD_EMAIL_SUCCESSFUL",
  RESET_PASSWORD_EMAIL_FAILED: "RESET_PASSWORD_EMAIL_FAILED",

  RESET_PASSWORD_REQUESTED: "RESET_PASSWORD_REQUESTED",
  RESET_PASSWORD_SUCCESSFUL: "RESET_PASSWORD_SUCCESSFUL",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",

  SIGNUP_REQUESTED: "SIGNUP_REQUESTED",
  SIGNUP_SUCCESSFUL: "SIGNUP_SUCCESSFUL",
  SIGNUP_FAILED: "SIGNUP_FAILED",

  SIGNIN_REQUESTED: "SIGNIN_REQUESTED",
  SIGNIN_SUCCESSFUL: "SIGNIN_SUCCESSFUL",
  SIGNIN_FAILED: "SIGNIN_FAILED",

  SIGNIN_WITH_FACEBOOK_REQUESTED: "SIGNIN_WITH_FACEBOOK_REQUESTED",
  SIGNIN_WITH_FACEBOOK_SUCCESSFUL: "SIGNIN_WITH_FACEBOOK_SUCCESSFUL",
  SIGNIN_WITH_FACEBOOK_FAILED: "SIGNIN_WITH_FACEBOOK_FAILED",

  SIGNIN_WITH_APPLE_REQUESTED: "SIGNIN_WITH_APPLE_REQUESTED",
  SIGNIN_WITH_APPLE_SUCCESSFUL: "SIGNIN_WITH_APPLE_SUCCESSFUL",
  SIGNIN_WITH_APPLE_FAILED: "SIGNIN_WITH_APPLE_FAILED",

  SIGNOUT_REQUESTED: "SIGNOUT_REQUESTED",
  SIGNOUT_FAILED: "SIGNOUT_FAILED",
  SIGNOUT_SUCCESSFUL: "SIGNOUT_SUCCESSFUL",

  VERIFY_EMAIL_REQUESTED: "VERIFY_EMAIL_REQUESTED",
  VERIFY_EMAIL_SUCCESSFUL: "VERIFY_EMAIL_SUCCESSFUL",
  VERIFY_EMAIL_FAILED: "VERIFY_EMAIL_FAILED",

  SIGNIN_WITH_GOOGLE_REQUESTED: "SIGNIN_WITH_GOOGLE_REQUESTED",

  RECOVER_EMAIL_REQUESTED: "RECOVER_EMAIL_REQUESTED",
  RECOVER_EMAIL_SUCCESSFUL: "RECOVER_EMAIL_SUCCESSFUL",
  RECOVER_EMAIL_FAILED: "RECOVER_EMAIL_FAILED",
};

export function handleForgotPasswordSubmit(email) {
  return {
    type: authActions.RESET_PASSWORD_EMAIL_REQUESTED,
    payload: {
      email: email,
    },
  };
}

export function handleResetPassword(password, oobCode) {
  return {
    type: authActions.RESET_PASSWORD_REQUESTED,
    payload: {
      password,
      oobCode,
    },
  };
}

export function handleRecoverEmail(oobCode) {
  return {
    type: authActions.RECOVER_EMAIL_REQUESTED,
    payload: {
      oobCode,
    },
  };
}

export function handleSignUpSubmit(name, email, password) {
  return {
    type: authActions.SIGNUP_REQUESTED,
    payload: {
      name,
      email,
      password,
    },
  };
}

export function handleSignInSubmit(email, password, rememberMeChecked) {
  return {
    type: authActions.SIGNIN_REQUESTED,
    payload: {
      email: email,
      password: password,
      rememberMeChecked: false,
    },
  };
}

export const handleSignOutRequest = () => {
  return {
    type: authActions.SIGNOUT_REQUESTED,
    payload: {},
  };
};

export const handleFacebookLogin = () => {
  return {
    type: authActions.SIGNIN_WITH_FACEBOOK_REQUESTED,
    payload: {},
  };
};

export const handleAppleLogin = () => {
  return {
    type: authActions.SIGNIN_WITH_APPLE_REQUESTED,
    payload: {},
  };
};

export const handleGoogleLogin = () => {
  return {
    type: authActions.SIGNIN_WITH_GOOGLE_REQUESTED,
    payload: {},
  };
};

export const handleVerifyEmail = (oobCode) => {
  return {
    type: authActions.VERIFY_EMAIL_REQUESTED,
    payload: {
      oobCode,
    },
  };
};
