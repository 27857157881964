import { Container } from "react-bootstrap";

import "../../css/Auth.css";

// TODO(Taman): decide artwork based on network speed
import artworkHiRes from "../../assets/images/not-found-artwork.png";
import arrowLeft from "../../assets/images/arrow-left.png";
import { NavLink } from "react-router-dom";
import NavbarContainer from "../../components/NavbarContainer";

import { useGate } from "statsig-react";

const NotFound = (props) => {
  const { value, isLoading } = useGate("web-router-v1");
  const routingEnabled = !isLoading && value;
  return (
    <>
      {!routingEnabled ? <NavbarContainer /> : null}
      <NotFoundCore />
    </>
  );
};

// A stopgap solution until navbar is launched
// TODO(Taman): Move this to NotFound when navbar is launched
export const NotFoundCore = () => {
  return (
    <Container className="d-flex flex-row justify-content-center align-items-start flex-wrap loginholder p-3 t-5 pt-3">
      <div className="pe-5 art-holder text-center">
        <img src={artworkHiRes} className="artwork" alt="Jhotika" />
      </div>
      <div className="form-holder">
        <RightSideSection />
      </div>
    </Container>
  );
};

const RightSideSection = () => {
  return (
    <div className="top-text d-flex flex-column">
      <div className="text-holder d-flex flex-column justify-content-center justify-content-md-start align-items-center align-items-md-start">
        <p
          style={{
            color: "#356AFC",
            fontSize: "42px",
            fontWeight: 700,
          }}
        >
          ERROR 404
        </p>
        <p
          className="text-md-start text-center"
          style={{
            color: "#5E6282",
            fontSize: "20px",
            fontWeight: 400,
          }}
        >
          The page you are looking for does not exist. It may have been moved.
        </p>
      </div>
      {"\n"}
      <div>
        <NavLink to="/">
          <button className="mt-3 btn btn-primary brea-button">
            <img src={arrowLeft} alt="arrow-back" /> Time-Travel Back to Home
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default NotFound;
