import { useEffect, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { handleUpdateCurrentCity } from "../../../redux/actions/profileActions";
import { handleClearAllActionsOfType } from "../../../redux/actions/actionActions";
import { isActionInflight } from "../../../selectors/inflightActions";
import { getError } from "../../../selectors/failedActions";
import { hasActionSucceeded } from "../../../selectors/successfulActions";

import { Modal, Button } from "react-bootstrap";
import Spinner from "../../components/Spinner";
import ReduxErrorMessage from "../../components/ReduxErrorMessage";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { searchCity } from "../../../services";

const ACTION_KEY = "UPDATE_CURRENT_CITY";

const CurrentCityModal = (props) => {
  const prevCurrentCity = props?.userData?.currentCity ?? "";
  const [currentCities, setCurrentCities] = useState([prevCurrentCity]);
  const [options, setOptions] = useState([]);

  const { error, isSuccessful, clearAllActions } = props;

  const handleSave = () => {
    if (currentCities.length !== 1) {
      return;
    }
    props.updateCurrentCity(currentCities[0]);
  };

  const handleClose = () => {
    clearAllActions();
    setCurrentCities([prevCurrentCity]);
    props.onHide();
  };

  const handleSearch = (query: string) => {
    const NUM_CITY_RESULTS = 5;
    searchCity(query, NUM_CITY_RESULTS).then((cities) => {
      setOptions(cities.map((city) => city.city + ", " + city.country));
    });
  };

  useEffect(() => {
    if (currentCities.length !== 1 || currentCities[0] !== prevCurrentCity) {
      clearAllActions();
    }
  }, [currentCities, prevCurrentCity, clearAllActions]);

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton={!props.isLoading}>
        <Modal.Title>Update Current City</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isSuccessful && <p className="text-success">Current City updated</p>}
        <AsyncTypeahead
          id="current-city-selection"
          disabled={props.isLoading}
          minLength={3}
          onSearch={handleSearch}
          options={options}
          selected={currentCities}
          onChange={(selected) => {
            setCurrentCities(selected);
          }}
        />
        <ReduxErrorMessage actionKey={ACTION_KEY} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={props.isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
        {prevCurrentCity?.trim() !== currentCities[0]?.trim() ? (
          <Button
            disabled={error || props.isLoading}
            variant="primary"
            onClick={handleSave}
          >
            <Spinner actionKey={ACTION_KEY} />
            Save Changes
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateCurrentCity: handleUpdateCurrentCity,
      clearAllActions: () => handleClearAllActionsOfType(ACTION_KEY),
    },
    dispatch
  );
};

function mapStateToProps(state) {
  return {
    isLoading: isActionInflight(state, ACTION_KEY),
    error: getError(state, ACTION_KEY),
    isSuccessful: hasActionSucceeded(state, ACTION_KEY),
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(CurrentCityModal);
