import React, { useEffect, useState } from "react";
import { validateInput } from "./DestinationCountryUtil";
export const CountryForm = (props: {
  countries: string,
  label: string,
  callback: (s: string) => void,
}) => {
  const [countries, setCountries] = useState(props.countries);
  const [validCountries, setValidCountries] = useState([]);

  const _callback = props.callback;
  const check = (v) => {
    if (v === props.value) {
      return;
    }
    const got = validateInput(v);
    const _validCountries = got.validCountries;
    const _invalidCountries = got.invalidCountries;
    if (_invalidCountries.length === 0) {
      const formattedNames = _validCountries.sort().join(", ");
      if (formattedNames !== countries) {
        _callback(formattedNames);
        setCountries(formattedNames);
        setValidCountries(_validCountries);
      }
    } else {
      setCountries(v);
    }
  };

  useEffect(() => {
    setCountries(props.countries);
  }, [props.countries]);

  return (
    <div key={props.label}>
      <h5>{props.label}</h5>
      <textarea
        key={props.label + "textarea"}
        rows={10}
        defaultValue={countries}
        style={{ width: 700 }}
        onChange={(e) => {
          check(e.target.value);
        }}
      />
      <div>
        <div style={{ maxWidth: 800 }}>{validCountries.join(", ")}</div>
        <button
          onClick={() =>
            navigator.clipboard.writeText(validCountries.join(", "))
          }
        >
          Copy
        </button>
      </div>
      <div style={{ marginTop: 40 }}>
        <h6>Help</h6>
        <ul>
          <li>British overseas - broverseas</li>
          <li>French overseas - froverseas</li>
          <li>Dutch overseas - duoverseas</li>
          <li>Schengen - schengen</li>
        </ul>
      </div>
      {validateInput(countries).invalidCountries.length > 0 && (
        <p style={{ color: "red", maxWidth: 800 }}>
          Invalid names -{" "}
          {validateInput(countries).invalidCountries.join("\r\n")}
        </p>
      )}
    </div>
  );
};
