import "../../css/Auth.css";
import artworkHiRes from "../../assets/images/artwork-left-hi-res.png";

const AuthPage = (props: { artwork: string, children: React.ReactNode }) => {
  return (
    <div className="d-flex flex-row justify-content-center align-items-start flex-wrap loginholder p-3 pt-3">
      <div className="art-holder text-center">
        <img
          src={props.artwork ?? artworkHiRes}
          className="artwork"
          alt="Jhotika"
        />
      </div>

      {props.children}
    </div>
  );
};
export default AuthPage;
