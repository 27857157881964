import { applyMiddleware, createStore } from "redux";
import rootReducer from "../reducers/index";
import { persistStore } from "redux-persist";
import combinedSaga from "../../sagas/index.js";

import createSagaMiddleware from "redux-saga";
const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
export const persistor = persistStore(store);
sagaMiddleware.run(combinedSaga);

export default store;
