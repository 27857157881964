import { useState } from "react";

import { handleUpdateUserFullname } from "../../../redux/actions/profileActions";
import { handleClearAllActionsOfType } from "../../../redux/actions/actionActions";

import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { isActionInflight } from "../../../selectors/inflightActions";
import { getError } from "../../../selectors/failedActions";
import { hasActionSucceeded } from "../../../selectors/successfulActions";

import {
  validateFullName,
  validateFullNameMessage,
} from "../../../services/utils";

import ButtonSpinner from "../../components/Spinner";

const ACTION_KEY = "UPDATE_NAME";

type Props = {
  error: string | null,
  isLoading: boolean,
  isSuccessful: boolean,
  show: boolean,
  userData: any,
  onHide: () => void,
  handleUpdateUserFullname: (name: string) => void,
};

const NameModal = (props: Props) => {
  const currentName = props.userData?.fullName;
  const [name, setName] = useState(currentName);
  const [validationError, setValidationError] = useState(null);

  const handleSave = () => {
    if (!validateFullName(name)) {
      setValidationError(validateFullNameMessage(name));
      return;
    } else {
      props.handleUpdateUserFullname(name.trim());
    }
  };

  const handleClose = () => {
    props.clearAllActions();
    setName(currentName);
    props.onHide();
  };

  const validateName = () => {
    return (
      name?.trim().length > 3 &&
      name.trim().indexOf(" ") !== -1 &&
      name !== currentName
    );
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton={!props.isLoading}>
        <Modal.Title>
          <h5 className="mt-3">Change Name</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100">
          <p className="text-success">
            {props.isSuccessful ? "Name successfully updated" : null}
          </p>

          <input
            autoComplete="name"
            className="brea-input settingInput user-profile"
            disabled={props.isLoading}
            id="name"
            name="name"
            placeholder="Enter name"
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              props.clearAllActions();
              setValidationError(validateFullNameMessage(e.target.value));
            }}
          />

          {props.error || validationError ? (
            <p className="text-danger">{props.error || validationError}</p>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={props.isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
        {validateName() ? (
          <Button
            disabled={props.isLoading}
            variant="primary"
            type="submit"
            onClick={handleSave}
          >
            <ButtonSpinner actionKey={ACTION_KEY} />
            Save Changes
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: isActionInflight(state, ACTION_KEY),
    error: getError(state, ACTION_KEY),
    isSuccessful: hasActionSucceeded(state, ACTION_KEY),
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleUpdateUserFullname,
      clearAllActions: () => handleClearAllActionsOfType(ACTION_KEY),
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(NameModal);
