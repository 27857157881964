import { useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { handleClearAllActionsOfType } from "../../../redux/actions/actionActions";
import { handleResetPassword } from "../../../redux/actions/authActions";
import { hasActionSucceeded } from "../../../selectors/successfulActions";
import { isActionInflight } from "../../../selectors/inflightActions";
import { validatePassword } from "../../../services/utils";

import "../../css/Auth.css";
import { NavLink, useSearchParams } from "react-router-dom";
import ReduxErrorMessage from "../../components/ReduxErrorMessage";
import Spinner from "../../components/Spinner";
import WelcomeCard from "./WelcomeCard";

const ACTION_KEY = "RESET_PASSWORD";

const ResetPassword = (props: {
  isLoading: boolean,
  passwordResetSuccessful: boolean,
  clearAllActions: () => void,
  handleResetPassword: (password: string, oobCode: string) => void,
}) => {
  return (
    <div className="form-holder">
      <WelcomeCard welcomeText="Reset password for " />
      {props.passwordResetSuccessful ? (
        <SuccessMessage />
      ) : (
        <ResetPasswordForm {...props} />
      )}
    </div>
  );
};

const SuccessMessage = () => {
  return (
    <div>
      <p className="text-success">
        Password successfully reset. Please login with your new password.
      </p>
      <NavLink to="/auth/signin" className="btn btn-primary brea-button">
        Login
      </NavLink>
    </div>
  );
};

const ResetPasswordForm = (props: {
  isLoading: boolean,
  clearAllActions: () => void,
  handleResetPassword: (password: string, oobCode: string) => void,
}) => {
  const [password, setPassword] = useState("");
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get("oobCode");
  return (
    <form>
      <input
        autoComplete="new-password"
        className="brea-input"
        disabled={props.isLoading}
        id="password"
        name="password"
        placeholder="Enter your new password"
        type="password"
        onChange={(e) => setPassword(e.target.value)}
      />

      <ReduxErrorMessage actionKey={ACTION_KEY} />
      <button
        className="mt-3 btn btn-primary brea-button"
        disabled={props.isLoading || !validatePassword(password)}
        type="submit"
        onClick={(e) => {
          props.handleResetPassword(password, oobCode);
        }}
      >
        <Spinner actionKey={ACTION_KEY} />
        Reset Password
      </button>
    </form>
  );
};

const matchStateToProps = (state) => ({
  isLoading: isActionInflight(state, ACTION_KEY),
  passwordResetSuccessful: hasActionSucceeded(state, ACTION_KEY),
});

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleResetPassword,
      clearAllActions: () => handleClearAllActionsOfType(ACTION_KEY),
    },
    dispatch
  );
};

export default connect(matchStateToProps, matchDispatchToProps)(ResetPassword);
