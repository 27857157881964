import { put, call, takeLatest, delay } from "redux-saga/effects";
import { authActions } from "../redux/actions/authActions";
import * as service from "../services/index";
import { catchEmpties, sagaError, sagaSuccess } from "./sagaUtils";
import { getAnalytics, logEvent } from "firebase/analytics";

import type { User } from "firebase/auth";

// TODO(Taman): Move to top level
const analytics = getAnalytics();

export function* signInWorker(action: {
  payload: {
    email: string,
    password: string,
  },
}) {
  // Intentional delay for smoother UX
  yield delay(1000);
  const data = action.payload;
  const errorMessage = catchEmpties(data);
  if (errorMessage) {
    yield sagaError(authActions.SIGNIN_FAILED, {
      message: errorMessage,
    });
    return;
  }

  try {
    const got: User = yield call(
      service.signInWithEmailService,
      data.email,
      data.password
    );
    // TODO(Taman): Remove after verification
    if (got?.error != null) {
      logEvent(analytics, "unwanted_error_field", {
        method: "signInWithEmailService",
      });
    }
    yield sagaSuccess(authActions.SIGNIN_SUCCESSFUL, got);
  } catch (e) {
    yield sagaError(authActions.SIGNIN_FAILED, e);
  }
}

export function* signUpWorker(action: {
  payload: {
    email: string,
    password: string,
    name: string,
  },
}) {
  // Intentional delay for smoother UX
  yield delay(500);
  if (catchEmpties(action.payload)) {
    yield sagaError(authActions.SIGNUP_FAILED, {
      message: "Please fill in all fields",
    });
    return;
  }

  try {
    const got = yield call(service.createFirebaseAccount, action.payload);
    // TODO(Taman): Remove after verification
    if (got?.error != null) {
      logEvent(analytics, "unwanted_error_field", {
        method: "createFirebaseAccount",
      });
    }
    yield sagaSuccess(authActions.SIGNUP_SUCCESSFUL, got);
    return;
  } catch (e) {
    yield sagaError(authActions.SIGNUP_FAILED, e);
  }
}

export function* signOutWorker(_action) {
  try {
    const got = yield call(service.signOutService);
    // TODO(Taman): Remove after verification
    if (got?.error != null) {
      logEvent(analytics, "unwanted_error_field", {
        method: "signOutService",
      });
    }
    yield sagaSuccess(authActions.SIGNOUT_SUCCESSFUL, got);
  } catch (e) {
    yield sagaError(authActions.SIGNOUT_FAILED, e);
  }
}

export function* resetPasswordEmailWorker(action: {
  payload: {
    email: string,
  },
}) {
  yield delay(1000);
  if (!action?.payload?.email) {
    yield sagaError(authActions.RESET_PASSWORD_EMAIL_FAILED, {
      message: "Input a valid email",
    });
    return;
  }

  try {
    const got = yield call(
      service.sendPasswordResetEmailService,
      action.payload
    );
    // TODO(Taman): Remove after verification
    if (got?.error != null) {
      logEvent(analytics, "unwanted_error_field", {
        method: "sendPasswordResetEmailService",
      });
    }
    yield sagaSuccess(authActions.RESET_PASSWORD_EMAIL_SUCCESSFUL, got);
  } catch (e) {
    yield sagaError(authActions.RESET_PASSWORD_EMAIL_FAILED, e);
  }
}

export function* resetPasswordWorker(action) {
  yield delay(1000);
  if (!action?.payload?.oobCode) {
    yield put({
      type: authActions.RESET_PASSWORD_FAILED,
      payload: { error: "Incorrect URL. Please try again from your email" },
    });
    return;
  }

  try {
    const got = yield call(service.resetPasswordService, action.payload);
    // TODO(Taman): Remove after verification
    if (got?.error != null) {
      logEvent(analytics, "unwanted_error_field", {
        method: "resetPasswordService",
      });
    }
    yield sagaSuccess(authActions.RESET_PASSWORD_SUCCESSFUL, got);
  } catch (e) {
    yield sagaError(authActions.RESET_PASSWORD_FAILED, e);
  }
}

export function* verifyEmailWorker(action) {
  yield delay(1000);
  if (!action?.payload?.oobCode) {
    sagaError(authActions.VERIFY_EMAIL_FAILED, {
      message: "Incorrect URL. Please try again from your email",
    });
    return;
  }
  try {
    const got = yield call(service.verifyEmail, action.payload);
    yield sagaSuccess(authActions.VERIFY_EMAIL_SUCCESSFUL, { ...got });
  } catch (e) {
    yield sagaError(authActions.VERIFY_EMAIL_FAILED, e);
  }
}

export function* recoverEmailWorker(action) {
  yield delay(1000);
  if (!action?.payload?.oobCode) {
    sagaError(authActions.RECOVER_EMAIL_FAILED, {
      message: "Incorrect URL. Please try again from your email",
    });
    return;
  }
  try {
    const got = yield call(service.recoverEmail, action.payload);
    yield sagaSuccess(authActions.RECOVER_EMAIL_SUCCESSFUL, { ...got });
  } catch (e) {
    yield sagaError(authActions.RECOVER_EMAIL_FAILED, e);
  }
}

export function* authSagas() {
  yield takeLatest(authActions.SIGNIN_REQUESTED, signInWorker);
  yield takeLatest(authActions.SIGNUP_REQUESTED, signUpWorker);
  yield takeLatest(
    authActions.SIGNIN_WITH_FACEBOOK_REQUESTED,
    service.signInWithFacebookService
  );
  yield takeLatest(
    authActions.SIGNIN_WITH_GOOGLE_REQUESTED,
    service.signInWithGoogleService
  );
  yield takeLatest(
    authActions.SIGNIN_WITH_APPLE_REQUESTED,
    service.signInWithAppleService
  );
  yield takeLatest(
    authActions.RESET_PASSWORD_EMAIL_REQUESTED,
    resetPasswordEmailWorker
  );
  yield takeLatest(authActions.RESET_PASSWORD_REQUESTED, resetPasswordWorker);
  yield takeLatest(authActions.SIGNOUT_REQUESTED, signOutWorker);

  yield takeLatest(authActions.VERIFY_EMAIL_REQUESTED, verifyEmailWorker);
  yield takeLatest(authActions.RECOVER_EMAIL_REQUESTED, recoverEmailWorker);
}
