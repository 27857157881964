import { useEffect, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { handleUpdateBioRequest } from "../../../redux/actions/profileActions";
import { handleClearAllActionsOfType } from "../../../redux/actions/actionActions";
import { isActionInflight } from "../../../selectors/inflightActions";
import { getError } from "../../../selectors/failedActions";
import { hasActionSucceeded } from "../../../selectors/successfulActions";

import { Modal, Button, Form } from "react-bootstrap";
import Spinner from "../../components/Spinner";
import ReduxErrorMessage from "../../components/ReduxErrorMessage";

const ACTION_KEY = "UPDATE_BIO";

const BioModal = (props) => {
  const currentBio = props?.userData?.bio ?? "";
  const [bio, setBio] = useState(currentBio);
  const { error, isSuccessful, clearAllActions } = props;

  const handleSave = () => {
    props.updateBio(bio.trim());
  };

  const handleClose = () => {
    clearAllActions();
    setBio(props?.userData?.bio);
    props.onHide();
  };

  useEffect(() => {
    if (bio !== currentBio) {
      clearAllActions();
    }
  }, [bio, currentBio, clearAllActions]);

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton={!props.isLoading}>
        <Modal.Title>Edit Bio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isSuccessful && <p className="text-success">Bio updated</p>}
        <Form.Control
          as="textarea"
          disabled={props.isLoading}
          rows={3}
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
        <ReduxErrorMessage actionKey={ACTION_KEY} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={props.isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
        {bio.trim() !== currentBio.trim() ? (
          <Button
            disabled={error || props.isLoading}
            variant="primary"
            onClick={handleSave}
          >
            <Spinner actionKey={ACTION_KEY} />
            Save Changes
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateBio: handleUpdateBioRequest,
      clearAllActions: () => handleClearAllActionsOfType(ACTION_KEY),
    },
    dispatch
  );
};

function mapStateToProps(state) {
  return {
    isLoading: isActionInflight(state, ACTION_KEY),
    error: getError(state, ACTION_KEY),
    isSuccessful: hasActionSucceeded(state, ACTION_KEY),
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(BioModal);
