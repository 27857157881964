import React from "react";
import "../../css/blog.css";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="p-5">
      <p className="Blog-TitleHeading">Deletion Policy</p>
      <hr />

      <p className="Blog-SubParagraph">Last Updated November 21, 2023</p>

      <div className="Blog-DetailContainer">
        <p className="Blog-SecondTitleHeading">
          | How Can I Delete My Information?
        </p>
        <p className="Blog-RegularParagaraph">
          We store data until it is no longer necessary to provide our services,
          or until your account is deleted - whichever comes first. This is a
          case-by-case determination that depends on things like the nature of
          the data, why it is collected and processed, and relevant legal or
          operational retention needs. For example, when you upload your
          documents to Jhotika vaults, you can access and delete that from
          within Jhotika Travel Manager app at any time, but the log of that
          upload operation is deleted after 6 months.
        </p>
        <p className="Blog-RegularParagaraph">
          When you delete your account, we make everything you have uploaded and
          created unavailable immediately. We delete everyhing you have uploaded
          and created, such as travels and documents, within 30 days from the
          account deletion, and you may or may not be able to retrieve your
          account within that time.
        </p>
        <p className="Blog-RegularParagaraph">
          {" "}
          To retrieve a deleted account, try signing in to Jhotika Website or
          apps.
        </p>
        <p className="Blog-RegularParagaraph">
          Information that might be related to you but were not created by you
          may or may not be deleted at a case by case basis.
        </p>
        <p className="Blog-RegularParagaraph">
          If you don't want to delete your account but want to temporarily stop
          using the Jhotika Products, you can sign our from your account
          instead.
        </p>
        <p className="Blog-RegularParagaraph">
          To delete your account at any time, please visit the Advanced Settings
          page from Jhotika Travel Manager app or the Settings page on{" "}
          <Link to="https://jhotika.com/user/settings">Jhotika Website</Link>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
