import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";

import { firebaseConfig } from "./firebaseConfig";
import { getAuth, updateProfile } from "firebase/auth";

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

export const updateProfileImage = async (payload: { imageFileUrl: string }) => {
  const result = await fetch(payload.imageFileUrl);
  const imageFile = await result.blob();
  return new Promise((resolve, reject) => {
    const user = auth.currentUser;
    if (user == null) {
      reject({ message: "Log in first to update your profile photo" });
      return;
    }
    const storageRef = getStorage(firebaseApp);
    const storage = ref(storageRef, `profile_images/${user.uid}/`);
    const metadata = {
      contentType: "image/jpg",
    };
    uploadBytes(storage, imageFile, metadata)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .then((url) => {
        return updateProfile(user, {
          photoURL: url,
        });
      })
      .then(() => {
        resolve(auth.currentUser);
        return;
      })
      .catch((error) => {
        reject({ message: error.message });
        return;
      });
  });
};
