import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { userSignedInSelector } from "../../../../selectors/user";

import { allCountries } from "./CountryData";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CountryForm } from "./CountryForm";

const VisaContainer = (props) => {
  return (
    <>
      <div style={{ marginBottom: 100, marginTop: 100 }}>
        <CountryForm
          countries=""
          label="Country list validator"
          callback={(s) => console.log(s)}
        />
      </div>
      <Table style={{ width: 800 }}>
        <thead>
          <tr style={{ columnSpan: 4 }}>
            <th>Countries Admin Panel</th>
          </tr>
          <tr>
            <th>Flag</th>
            <th>Country</th>
            <th>ISO2</th>
            <th>Visa Info</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(allCountries)
            .sort((country) => country.fullName)
            .map((country) => {
              return (
                <tr key={country.fullName}>
                  <td>
                    <img
                      alt="Country flag "
                      className="spot-img"
                      src={countryFlagsFromIso2(country.iso2)}
                    />
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <h5>{country.fullName}</h5>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <h5>{country.iso2}</h5>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <Link to={`/admin/visa/${country.iso2}`}>Edit</Link>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

const countryFlagsFromIso2 = (iso2) => {
  return `https://storage.googleapis.com/brea-srv/country-flags-srv/${iso2}.png`;
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

function mapStateToProps(state) {
  return {
    viewerSignedIn: userSignedInSelector(state),
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(VisaContainer);
