import { useState } from "react";

import { handleUpdateStatusRequest } from "../../../../redux/actions/profileActions";
import { handleClearAllActionsOfType } from "../../../../redux/actions/actionActions";

import { Modal, Button, Dropdown, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { isActionInflight } from "../../../../selectors/inflightActions";
import { getError } from "../../../../selectors/failedActions";
import { hasActionSucceeded } from "../../../../selectors/successfulActions";

import ButtonSpinner from "../../../components/Spinner";
import { AVAILABILITY_STATUS } from "./AvailabilityStatus";
import ReduxErrorMessage from "../../../components/ReduxErrorMessage";

const ACTION_KEY = "UPDATE_STATUS";

type Props = {
  error: string | null,
  isLoading: boolean,
  isSuccessful: boolean,
  show: boolean,
  userData: any,
  onHide: () => void,
};

const AvailabilityStatusEditModal = (props: Props) => {
  const prevStatus = props.status;
  const [note, setNote] = useState(prevStatus?.note);
  const [availability, setAvailability] = useState(prevStatus?.availability);
  const [title, setTitle] = useState(prevStatus?.title);

  const handleSave = () => {
    props.handleUpdateStatusRequest(note, availability, title);
  };

  const handleClose = () => {
    props.clearAllActions();
    setNote(prevStatus.note);
    setTitle(prevStatus.title);
    setAvailability(prevStatus.availability);
    props.onHide();
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton={!props.isLoading}>
        <Modal.Title>
          <h5 className="mt-3">Change Status</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100">
          <p className="text-success" style={{ textAlign: "center" }}>
            {props.isSuccessful ? "Status successfully updated" : null}
          </p>
          <AvailibilitySection
            prevStatus={prevStatus}
            availability={availability}
            setAvailability={setAvailability}
          />
          <div style={{ height: 20 }} />
          <TitleSection setTitle={setTitle} title={title} />
          <div style={{ height: 20 }} />
          <NoteSection
            note={note}
            setNote={setNote}
            clearAllActions={props.clearAllActions}
          />
          <ReduxErrorMessage actionKey={ACTION_KEY} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={props.isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          disabled={props.isLoading}
          variant="primary"
          type="submit"
          onClick={handleSave}
        >
          <ButtonSpinner actionKey={ACTION_KEY} />
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const NoteSection = (props) => {
  return (
    <>
      <SectionTitle title="Note" />
      <input
        className="brea-input settingInput user-profile"
        disabled={props.isLoading}
        id="note"
        name="note"
        placeholder="Enter a note"
        type="text"
        value={props.note}
        onChange={(e) => {
          props.setNote(e.target.value);
          props.clearAllActions();
        }}
      />
    </>
  );
};

const AvailibilitySection = (props) => {
  return (
    <>
      <SectionTitle title="Availabiliy" />
      <Form className="d-flex flex-sm-row flex-column justify-content-around">
        {Object.values(AVAILABILITY_STATUS).map((status, idx) => (
          <div
            style={{
              borderRadius: 4,
              border: status.color,
              borderStyle: "solid",
              paddingTop: 6,
              paddingBottom: 6,
              paddingLeft: 12,
              paddingRight: 12,
            }}
            key={idx}
            className="d-flex flex-row align-items-between"
          >
            <Form.Check
              type="radio"
              name="availability"
              label={status.name}
              key={status.name}
              value={status.idx}
              checked={props.availability === status.idx}
              style={{ color: status.color }}
              onChange={() => {
                const value = parseInt(status.idx);
                props.setAvailability(value);
              }}
            />
          </div>
        ))}
      </Form>
    </>
  );
};

const TitleSection = (props) => {
  return (
    <>
      <SectionTitle title="Status Title" />
      <Dropdown
        className="mt-3"
        onSelect={(e) => {
          props.setTitle(e);
        }}
      >
        <Dropdown.Toggle
          id="status-title"
          style={{ color: "black", backgroundColor: "white" }}
        >
          <span style={{ fontFamily: "Poppins" }}>{props.title}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ height: "250px", overflowX: "scroll" }}>
          {STATUSES.map((status, idx) => (
            <Dropdown.Item eventKey={status} key={idx}>
              {status}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const STATUSES = [
  "🛑 Offline",
  "🚫 Nirvana",
  "👜 Packed",
  "🚌 OnRoute",
  "😴 Slumber",
  "🌍 Linked",
  "📵 Quietude",
  "🌐 Webbed",
  "💤 Napping",
  "🚷 Isolate",
  "🛌 Restful",
  "📴 Silenced",
  "🌎 GlobeTrek",
  "💤 Repose",
  "📶 Linked",
  "🏞️ WiFiWilds",
  "🏞️ Mountain Retreat",
  "🏖️ Beach Bliss",
  "🌲 Forest Haven",
  "⛰️ Summit Serenity",
  "🌅 Sunset Escapes",
  "🌊 Seaside Sojourn",
  "🌳 Woodland Wander",
  "🏕️ Campfire Coziness",
  "🌺 Tropical Tranquility",
  "❄️ Winter Wonderland",
  "🌄 Horizon Hues",
];

const SectionTitle = (props) => {
  return (
    <p style={{ fontFamily: "Poppins", fontWeight: 300 }}>{props.title}</p>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: isActionInflight(state, ACTION_KEY),
    error: getError(state, ACTION_KEY),
    isSuccessful: hasActionSucceeded(state, ACTION_KEY),
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleUpdateStatusRequest,
      clearAllActions: () => handleClearAllActionsOfType(ACTION_KEY),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(AvailabilityStatusEditModal);
