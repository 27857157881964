import { useEffect } from "react";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userSignedInSelector } from "../../../selectors/user";

const SignedInRedirect = (props) => {
  let navigate = useNavigate();
  const { viewerSignedIn } = props;
  useEffect(() => {
    if (viewerSignedIn === true) {
      return navigate("/");
    }
  }, [viewerSignedIn, navigate]);
};

function mapStateToProps(state) {
  return {
    viewerSignedIn: userSignedInSelector(state),
  };
}

export default connect(mapStateToProps)(SignedInRedirect);
