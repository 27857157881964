import React from "react";
import AppStoreBtn from "../Component/AppStore.svg";
import GooglePlay from "../Component/GooglePlay.svg";
import "animate.css";
import Hero_Plain_Img from "./hero.svg";
import "../ui/css/AppLandingPage-banner.css";

export const APP_STORE_URL =
  "https://apps.apple.com/us/app/jhotika/id6472742516";
export const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.jhotika.jtm";

const LandingPageBanner = () => {
  return (
    <div className="container d-flex flex-column align-items-center justify-content-center">
      <div className="row justify-content-center">
        <div className="col-12 col-md-12 col-lg-8 text-center">
          <div className="row">
            <div className="col-12 col-md d-flex justify-content-center">
              <div className=" p-2">
                <span className="banner-title">
                  Stress Free Trip Management
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12  d-flex justify-content-center">
              <div className=" p-3">
                <span className="banner-text">
                  Jhotika is your travel companion,
                  <span className="banner-sub-text">
                    {" "}
                    simplifying trip planning,
                  </span>{" "}
                  and itinerary creation. Download Jhotika today to plan and
                  manage your journeys with ease!
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-5">
            <a
              href={APP_STORE_URL}
              target="_blank"
              rel="noreferrer"
              className=" btn-outline-none p-0 btn-hover-effect"
            >
              <img src={AppStoreBtn} alt="App Store" className="img-fluid" />
            </a>
            <a
              href={GOOGLE_PLAY_URL}
              target="_blank"
              rel="noopener noreferrer"
              className=" btn-outline-none p-0 ml-3 btn-hover-effect"
            >
              <img src={GooglePlay} alt="Google Play" className="img-fluid" />
            </a>
          </div>
          <div className="row">
            <div className="col-12  d-flex justify-content-center">
              <div className=" p-2 mt-5">
                <img
                  src={Hero_Plain_Img}
                  alt="hero"
                  className="img-fluid Hero"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageBanner;
