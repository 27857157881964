import { useRef, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import UploadDP from "../../assets/images/userAvatar.png";

import { Modal, Button, Spinner } from "react-bootstrap";
import BreaAvatarEditor from "./AvatarEditor";
import { handleUpdateProfileImageRequest } from "../../../redux/actions/profileActions";
import { isActionInflight } from "../../../selectors/inflightActions";
import { hasActionSucceeded } from "../../../selectors/successfulActions";
import ReduxErrorMessage from "../../components/ReduxErrorMessage";
import { handleClearAllActionsOfType } from "../../../redux/actions/actionActions";

const ACTION_KEY = "UPDATE_PROFILE_IMAGE";

const ProfilePicModal = (props: {
  userData: any,
  show: boolean,
  onHide: () => void,
  handleUpdateProfileImageRequest: (string) => void,
  isLoading: boolean,
  hasActionSucceeded: boolean,
  clearAllActions: () => void,
}) => {
  const [profileImageUrl, setProfileImageUrl] = useState(
    props.userData.profileImageUrl
  );

  const handleImageUpload = (event) => {
    props.clearAllActions();
    const selectedImage = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setProfileImageUrl(e.target.result);
    };
    reader.readAsDataURL(selectedImage);
  };

  const handleClose = () => {
    setProfileImageUrl(props.userData.profileImageUrl);
    props.clearAllActions();
    props.onHide();
  };

  const editorRef = useRef(null);

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton={false}>
        <Modal.Title>
          <h5 className="mt-3">Update Profile Photo</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex-column justify-content-center align-content-center">
          <div style={{ textAlign: "center" }}>
            {profileImageUrl === props.userData.profileImageUrl ? (
              <img
                style={{ height: "9rem", borderRadius: "50%" }}
                src={profileImageUrl || UploadDP}
                alt="Profile"
              />
            ) : (
              <BreaAvatarEditor
                newImageUrl={profileImageUrl}
                editorRef={editorRef}
              />
            )}
          </div>
          <div
            style={{ width: "100%", textAlign: "center", marginTop: "1rem" }}
          >
            <form>
              {props.hasActionSucceeded === true ? (
                <p className="text-success">Profile Photo Updated</p>
              ) : (
                <label className="file-upload">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    type="file"
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                  />
                  <div className="mt-3 btn btn-primary brea-button">
                    Upload Photo
                  </div>
                </label>
              )}

              {props.isLoading && (
                <div className="d-flex justify-content-center">
                  <Spinner />
                </div>
              )}
            </form>
          </div>
          <ReduxErrorMessage actionKey={ACTION_KEY} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={props.loading}
          variant="secondary"
          onClick={() => handleClose()}
        >
          Close
        </Button>
        <Button
          disabled={
            props.loading ||
            props.hasActionSucceeded ||
            profileImageUrl === props.userData.profileImageUrl
          }
          variant="primary"
          type="submit"
          onClick={() => {
            const dataUrl = editorRef.current.getImage().toDataURL();
            props.handleUpdateProfileImageRequest(dataUrl);
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: isActionInflight(state, ACTION_KEY),
    hasActionSucceeded: hasActionSucceeded(state, ACTION_KEY),
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleUpdateProfileImageRequest,
      clearAllActions: () => handleClearAllActionsOfType(ACTION_KEY),
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(ProfilePicModal);
