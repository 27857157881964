import { createSelector } from "reselect";
import { getKey } from "../redux/actions/actionUtils";

export const successfulAcionsSelector = (state) => {
  return state?.successfulActions ?? [];
};

export const hasActionSucceeded = createSelector(
  [successfulAcionsSelector, (state, actionKey) => actionKey],

  (successfulActions, actionKey) => {
    return successfulActions.includes(getKey(actionKey));
  }
);
