import { initializeApp } from "firebase/app";
import {
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateEmail,
  updatePassword,
  updateProfile,
  verifyPasswordResetCode,
} from "firebase/auth";
import {
  genUrl,
  validateEmail,
  validateFullName,
  validateFullNameMessage,
  validatePassword,
} from "./utils";

import { firebaseConfig } from "./firebaseConfig";

import type { User, UserCredential } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const analytics = getAnalytics();

let unsubscribeFromFirebaseAuthStateChange = () => {};

export function sendPasswordResetEmailService(payload) {
  const { email } = payload;
  return new Promise((resolve, reject) => {
    sendPasswordResetEmail(auth, email)
      .then(function () {
        resolve(true);
      })
      .catch(function (error) {
        switch (error.code) {
          case "auth/invalid-email":
            reject({ message: "Invalid e-mail address format" });
            break;
          case "auth/user-disabled":
            reject({ message: "This account has been disabled" });
            break;
          case "auth/user-not-found":
            reject({ message: "This account does not exist" });
            break;
          default:
            reject({
              message: error?.message ?? "Check your internet connection",
            });
        }
        return;
      });
  });
}

export const resetPasswordService = async (payload) => {
  const { password, oobCode } = payload;
  try {
    const email = await verifyPasswordResetCode(auth, oobCode);
    if (email) {
      await confirmPasswordReset(auth, oobCode, password);
      return { success: true };
    } else {
      throw new Error("Incorrect URL. Please try again from your email");
    }
  } catch (error) {
    let errorMessage;
    switch (error.code) {
      case "auth/expired-action-code":
        errorMessage =
          "This link has expired. Please try again from Forgot Password.";
        break;
      case "auth/invalid-action-code":
        errorMessage = "Incorrect URL. Please try again from your email";
        break;
      case "auth/user-disabled":
        errorMessage = "This account has been disabled";
        break;
      case "auth/user-not-found":
        errorMessage = "This account does not exist";
        break;
      case "auth/weak-password":
        errorMessage = "Password is too weak";
        break;
      default:
        errorMessage = error?.message ?? "Please try again later";
    }
    throw new Error(errorMessage);
  }
};

export function createFirebaseAccount(payload: {
  email: string,
  password: string,
  name: string,
}): Promise<User> {
  const { email, password } = payload;
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user: User = userCredential.user;
        updateUserFullname({ name: payload.name });
        sendEmailVerification(user);
        // register user to Jhotika's database
        user
          .getIdToken()
          .then((idToken: string) => {
            registerUser(getHeader(idToken)).catch((error) => {
              logEvent(analytics, "Error registering user to DB", {
                error: error,
                uuid: user.uid,
              });
            });
          })
          .then(() => {
            resolve({ user: user });
          })
          .catch((error) => {
            reject({ message: error.message });
          });
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/email-already-in-use":
            reject({ message: "This email address is already taken" });
            break;
          case "auth/invalid-email":
            reject({ message: "Invalid e-mail address format" });
            break;
          case "auth/weak-password":
            reject({ message: "Password is too weak" });
            break;
          default:
            reject({
              message: error?.message ?? "Check your internet connection",
            });
        }
      });
  });
}

export function signInWithGoogleService() {
  return new Promise((resolve, reject) => {
    const provider = new GoogleAuthProvider();
    provider.addScope("email");
    provider.addScope("profile");
    signInWithPopup(auth, provider)
      .then((result) => {
        resolve({ user: result.user });
      })
      .catch((error) => {
        reject({ message: error.message });
      });

    // The signed-in user info.
  });
}

export function signInWithAppleService() {
  // TODO(Taman): Implement
  return new Promise((resolve) => {});
}

export function signInWithFacebookService() {
  return new Promise((resolve, reject) => {
    const provider = new FacebookAuthProvider();
    provider.addScope("email");
    provider.addScope("profile");
    signInWithPopup(auth, provider)
      .then((result) => {
        resolve({ user: result.user });
      })
      .catch((error) => {
        reject({ message: error.message });
      });

    // The signed-in user info.
  });
}

export const signInWithEmailService = async (
  email,
  password
): Promise<User> => {
  if (!validateEmail(email)) {
    throw new Error("Invalid email address format");
  }
  if (!validatePassword(password)) {
    throw new Error("Password must be at least 6 characters long");
  }
  try {
    const userCred: UserCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user: User | null = userCred?.user;
    if (user == null) {
      throw new Error("Incorrect email or password");
    }
    if (user?.emailVerified === true) {
      return user;
    } else {
      await sendEmailVerification(user);
      throw new Error(
        "Email not verified. Please check your inbox for email verification link"
      );
    }
  } catch (error) {
    let errorMessage: string;
    switch (error.code) {
      case "auth/invalid-email":
        errorMessage = "Invalid e-mail address format";
        break;
      case "auth/user-disabled":
        errorMessage = "This account has been disabled";
        break;
      case "auth/user-not-found":
        errorMessage = "This account does not exist";
        break;
      case "auth/missing-password":
      case "auth/missing-email":
      case "auth/wrong-password":
        errorMessage = "Incorrect email or password";
        break;
      case "auth/too-many-requests":
        errorMessage = "Too many requests. Try again later";
        break;
      default:
        errorMessage = error.message ?? "Check your internet connection";
    }
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw new Error(errorMessage);
  }
};

export const signOutService = async () => {
  await unsubscribeFromFirebaseAuthStateChange();
  await signOut(auth).catch((error) => {
    logEvent(analytics, "Error signing out", {
      error: error,
      uid: auth?.currentUser?.uid,
    });

    throw new Error(error.message);
  });
};

// Utils

function getCurUser() {
  return new Promise((resolve) => {
    const user = auth.currentUser;
    if (user) {
      resolve(user);
    } else {
      unsubscribeFromFirebaseAuthStateChange = auth.onAuthStateChanged(
        (user) => {
          resolve(user || null);
        }
      );
    }
  });
}

const getHeader = (idToken: string): string => {
  return {
    Authorization: "Bearer " + idToken,
    "Content-Type": "application/json",
  };
};

export function fetchIdTokenAndExecute(fn, payload) {
  return new Promise((resolve) => {
    getCurUser()
      .then((user) => {
        if (user) {
          user
            .getIdToken(false)
            .then((idToken) => {
              resolve(fn(payload, getHeader(idToken), user.uid));
            })
            .catch((error) => {
              resolve(fn(payload, null));
            });
        } else {
          resolve(fn(payload, null));
        }
      })
      .catch((error) => {
        resolve(fn(payload, null));
      });
  });
}

export const updateJtmProfile = (payload, headers) => {
  return new Promise((resolve, reject) => {
    fetch(genUrl("users"), {
      method: "PATCH",
      headers,
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((json) => {
            reject({ message: json.error });
          });
        }
      })
      .then((json) => {
        resolve(json);
        return;
      })
      .catch((error) => {
        reject({ message: error.message });
      });
  });
};

export const registerUser = async (headers) => {
  await fetch(genUrl("users"), {
    method: "POST",
    headers,
  });
};

export const updateUserPassword = async (
  oldPassword: string,
  newPassword: string,
  handleLoading: () => {},
  handleSuccess: () => {},
  handleError: (error: string) => {}
) => {
  const credential = EmailAuthProvider.credential(
    auth.currentUser.email,
    oldPassword
  );

  const userCred = await reauthenticateWithCredential(
    auth.currentUser,
    credential
  ).catch((error) => {
    switch (error.code) {
      case "auth/invalid-email":
        handleError("Invalid e-mail address format");
        break;
      case "auth/user-disabled":
        handleError("This account has been disabled");
        break;
      case "auth/user-not-found":
        handleError("This account does not exist");
        break;
      case "auth/wrong-password":
        handleError("Incorrect password");
        break;
      case "auth/too-many-requests":
        handleError("Too many requests. Try again later");
        break;
      default:
        handleError(error.message);
        break;
    }
  });

  if (userCred?.user == null) {
    handleError("Incorrect password");
    return;
  }

  return new Promise((resolve) => {
    updatePassword(auth.currentUser, newPassword)
      .then(() => {
        handleSuccess();
        resolve({ success: true });
        return;
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/weak-password":
            handleError("Password is too weak");
            break;
          default:
            handleError("Check your internet connection");
        }
      });
  });
};

// This is a special method that doesn't depend on Saga-Redux flow
export const updateUserEmail = async (payload: {
  newEmail: string,
  password: string,
}): Promise<User> => {
  const { newEmail, password } = payload;
  const userCred: UserCredential = await reauthenticateWithCredential(
    auth.currentUser,
    EmailAuthProvider.credential(auth.currentUser.email, password)
  ).catch((error) => {
    let message;
    switch (error.code) {
      case "auth/invalid-email":
        message = "Invalid e-mail address format";
        break;
      case "auth/user-disabled":
        message = "This account has been disabled.";
        break;
      case "auth/wrong-password":
      case "auth/missing-password":
        message = "Incorrect password. Try again";
        break;
      case "auth/too-many-requests":
        message = "Too many requests. Try again later";
        break;
      default:
        message = error.message;
    }
    throw new Error(message);
  });
  if (userCred.error) {
    throw new Error(userCred.error);
  } else if (userCred?.user == null) {
    throw new Error("Incorrect password");
  }
  return new Promise((resolve, reject) => {
    updateEmail(auth.currentUser, newEmail)
      .then(() => {
        sendEmailVerification(auth.currentUser).then(() => {
          resolve(auth.currentUser);
        });
        return;
      })
      .catch((error) => {
        let errorMessage;
        switch (error.code) {
          case "auth/invalid-email":
            errorMessage = "Invalid e-mail address format";
            break;
          case "auth/email-already-in-use":
            errorMessage = "This email address is already taken";
            break;
          default:
            errorMessage = error?.message ?? "Check your internet connection";
            break;
        }
        reject({ message: errorMessage });
        return;
      });
  });
};

export const updateUserFullname = (payload: { name: string }) => {
  const { name } = payload || {};
  return new Promise((resolve, reject) => {
    if (name == null) {
      throw new Error("Name cannot be empty");
    }
    if (!validateFullName(name)) {
      throw new Error(validateFullNameMessage(name));
    }
    updateProfile(auth.currentUser, {
      displayName: name,
    })
      .then(() => {
        resolve(auth.currentUser);
        return;
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-display-name":
            reject({ message: "Invalid display name" });
            break;
          case "auth/user-disabled":
            reject({ message: "This account has been disabled" });
            break;
          case "auth/user-not-found":
            reject({ message: "This account does not exist" });
            break;
          default:
            reject({
              message: error?.message ?? "Check your internet connection",
            });
        }
        return;
      });
  });
};

// @throws
export const verifyEmail = async (payload: {
  oobCode: string,
}): {
  success: boolean,
} => {
  const { oobCode } = payload;
  try {
    const email = await verifyPasswordResetCode(auth, oobCode);
    if (email) {
      await applyActionCode(auth, oobCode);
      return { success: true };
    } else {
      throw new Error({
        message: "Incorrect URL. Please try again from your email",
        code: "auth/brea-action-code",
      });
    }
  } catch (error) {
    let errorMessage;
    switch (error.code) {
      case "auth/brea-action-code":
        errorMessage = error.message;
        break;
      case "auth/expired-action-code":
        errorMessage =
          "This link has expired. Please try again from Forgot Password.";
        break;
      case "auth/invalid-action-code":
        errorMessage = "Incorrect URL. Please try again from your email";
        break;
      case "auth/user-disabled":
        errorMessage = "This account has been disabled";
        break;
      case "auth/user-not-found":
        errorMessage = "This account does not exist";
        break;
      case "auth/weak-password":
        errorMessage = "Password is too weak";
        break;
      default:
        errorMessage = "Please try again later";
    }
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw new Error(errorMessage);
  }
};

export const updateStatus = (payload, headers) => {
  const { note, availability, title } = payload;
  return new Promise((resolve, reject) => {
    fetch(genUrl("users/availabilityStatus"), {
      method: "POST",
      headers,
      body: JSON.stringify({
        note: note,
        availability: availability,
        title: title,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          reject({ message: "Something went wrong. Please try again later" });
          return;
        }
      })
      .then((json) => {
        resolve(json);
        return;
      })
      .catch((error) => {
        reject({ message: error.message });
      });
  });
};

export const searchCity = async (pattern, limit) => {
  const response = await fetch(
    genUrl("search/cities?") +
      new URLSearchParams({ query: pattern, limit: limit }),
    {}
  );

  if (response.ok) {
    const data = await response.json();
    const cities = data?.cities ?? [];
    return cities;
  } else {
    throw new Error("Something went wrong. Please try again later");
  }
};

export const updateCurrentCity = (payload, headers) => {
  const { currentCity } = payload;
  return new Promise((resolve, reject) => {
    fetch(genUrl("users"), {
      method: "PATCH",
      headers,
      body: JSON.stringify({
        currentCity: currentCity,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          reject({ message: "Something went wrong. Please try again later" });
          return;
        }
      })
      .then((json) => {
        resolve(json);
        return;
      })
      .catch((error) => {
        reject({ message: error.message });
      });
  });
};

export const deleteAccount = async (payload, headers) => {
  // verify the current user's credentials
  await genReauthenticateUser(
    auth.currentUser,
    payload.email,
    payload.password
  );
  const response = await fetch(genUrl("users/deleteAccount"), {
    method: "POST",
    body: JSON.stringify({}),
    headers,
  });
  if (response.ok) {
    await signOutService();
  } else {
    throw new Error("Something went wrong. Please try again later");
  }
};

export const recoverEmail = async (payload: {
  oobCode: string,
}): {
  success: boolean,
} => {
  const { oobCode } = payload;
  try {
    const info = await checkActionCode(auth, oobCode);
    if (!info) {
      throw new Error({
        message: "Incorrect URL. Please try again from your email",
        code: "auth/brea-action-code",
      });
    }
    const restoredEmail = info?.data?.email;
    await applyActionCode(auth, oobCode);
    await sendPasswordResetEmail(auth, restoredEmail);
    return { restoredEmail: restoredEmail };
  } catch (error) {
    let errorMessage;
    switch (error.code) {
      case "auth/brea-action-code":
        errorMessage = error.message;
        break;
      case "auth/email-already-in-use":
        errorMessage = "This email address is already in use. Try signing in";
        break;
      case "auth/expired-action-code":
        errorMessage =
          "This link has expired. Please try again from Forgot Password.";
        break;
      case "auth/invalid-action-code":
        errorMessage = "Incorrect URL. Please try again from your email";
        break;
      case "auth/user-disabled":
        errorMessage = "This account has been disabled";
        break;
      case "auth/user-not-found":
        errorMessage = "This account does not exist";
        break;
      default:
        errorMessage = "Please try again later";
    }
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw new Error(errorMessage);
  }
};

export const genReauthenticateUser = async (currentUser, email, password) => {
  const credential = EmailAuthProvider.credential(email, password);
  const userCred: UserCredential = await reauthenticateWithCredential(
    currentUser,
    credential
  ).catch((error) => {
    let message;
    switch (error.code) {
      case "auth/invalid-email":
        message = "Invalid e-mail address format";
        break;
      case "auth/user-disabled":
        message = "This account has been disabled.";
        break;
      case "auth/wrong-password":
      case "auth/missing-password":
        message = "Incorrect password. Try again";
        break;
      case "auth/too-many-requests":
        message = "Too many requests. Try again later";
        break;
      default:
        message = error.message;
    }
    throw new Error(message);
  });
  if (userCred.error) {
    throw new Error(userCred.error);
  } else if (userCred?.user == null) {
    throw new Error("Incorrect password");
  }
  return userCred.user;
};

export const genGetUserUuidFromUsername = async (username) => {
  const response = await fetch(genUrl(`users/uuid/${username}`));
  if (response.ok) {
    try {
      const data = await response.json();
      return data?.uuid;
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};
