import { getKey, getDone, getLoading } from "../actions/actionUtils";

export const DEFAULT_REDUCER = [];

const loadings = (state = DEFAULT_REDUCER, action) => {
  const type = action.type;
  if (action.type === "CLEAR_ALL_ACTIONS_REQUESTED") {
    return [...state].filter((item) => action.payload.actionKey !== item);
  }
  if (getDone(type)) {
    const tmp = state.filter((item) => getKey(type) !== item);
    return tmp;
  } else if (getLoading(type)) {
    return [...state, getKey(type)];
  }
  return state;
};

export default loadings;
