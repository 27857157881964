import { Outlet } from "react-router-dom";

import "../../css/Auth.css";

// TODO(Taman): decide artwork based on network speed
import artworkHiRes from "../../assets/images/artwork-left-hi-res.png";
import AuthPage from "./AuthPage";

const Auth = (props) => {
  return (
    <AuthPage artwork={artworkHiRes}>
      <Outlet />
    </AuthPage>
  );
};

export default Auth;
