import React, { useEffect, useRef, useState } from "react";
import "../ui/css/WhatCanJhoticaDo.css";
import AppStoreBtn from "./AppStore.svg";
import GooglePlay from "./GooglePlay.svg";
import Spain from "./Spain.svg";
import Tour from "./tour.svg";
import Congrats from "./congratulations.svg";
import ToDo from "./To-Do.svg";
import Budget from "./Budget.svg";
import VisaInfo from "./visa_info.svg";
import Eating_Place from "./eating_place.svg";
import SmartTravel from "./smart travel.svg";
import profileBadge from "./profile_complete.svg";
import visitedOneCountryBadge from "./visited country badge.svg";
import visitedFiftyCountryBadge from "./visited 50 country badge.svg";
import visitedHundredCountryBadge from "./visited 100 country badge.svg";
import FAQPLUS from "./faq_plus.svg";
import FAQCROSS from "./faq_cross.svg";
import CountUp from "react-countup";

import { GOOGLE_PLAY_URL, APP_STORE_URL } from "./LandingPage-banner";

const WhatCanJhoticaDo = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const downloadSectionRef = useRef(null);

  const handleScrollToDownloadSection = () => {
    downloadSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const faqs = [
    {
      question: "What is Jhotika?",
      answer:
        "Jhotika is your travel companion, simplifying trip planning and itinerary creation. It's designed to plan your journeys with ease and stress-free.",
    },
    {
      question: "How can I download the Jhotika app?",
      answer:
        "Jhotika is available for download on both iOS and Android devices.<br/>" +
        `<a href=${GOOGLE_PLAY_URL} target='_blank'>👉 Google Play</a><br/>` +
        `<a href=${APP_STORE_URL} target='_blank'>🍏 App Store</a>`,
    },
    {
      question: "How do I sign up for Jhotika?",
      answer:
        "Signing up is easy! Visit " +
        "<a href='https://jhotika.com/auth/signup'>https://jhotika.com/auth/signup</a>" +
        " and provide your email and a password to create your account.",
    },
    {
      question: "Can I use Jhotika without an account?",
      answer:
        "No, you must create an account to use Jhotika. This allows us to provide you with a personalized experience.",
    },
    {
      question: "What features does Jhotika offer for travelers?",
      answer:
        "Jhotika offers itinerary planning, packing and to-do list management, passport, and visa tracker, destination recommendations, and more to enhance your travel journey.",
    },
    {
      question: "How can Jhotika help me plan my trips?",
      answer:
        "Jhotika assists you in creating detailed itineraries, suggesting activities, managing travel essentials, and documenting your adventures.",
    },
    {
      question: "Is Jhotika available on both iOS and Android?",
      answer:
        "Absolutely! Jhotika is designed for both iOS and Android platforms, ensuring accessibility for all users.",
    },
    {
      question: "How can Jhotika help me plan my trips?",
      answer:
        "Jhotika assists you in creating detailed itineraries, suggesting activities, managing travel essentials, and documenting your adventures.",
    },
    {
      question: "Are there any subscription plans for Jhotika?",
      answer:
        "Currently, Jhotika offers free accounts that provide access to our core features. We may introduce a paid subscription model in the future, but Jhotika will always have a free version for its users.",
    },

    // Add more question-answer pairs as needed
  ];

  const [showAnswers, setShowAnswers] = useState(
    Array(faqs.length).fill(false)
  );
  const [activeQuestion, setActiveQuestion] = useState(null);

  const toggleAnswer = (index) => {
    if (index === activeQuestion) {
      setActiveQuestion(null); // Toggle off if the clicked question is already active
      const updatedShowAnswers = showAnswers.map((value, i) =>
        i === index ? !value : false
      );
      setShowAnswers(updatedShowAnswers);
    } else {
      const updatedShowAnswers = showAnswers.map((value, i) =>
        i === index ? !value : false
      );
      setShowAnswers(updatedShowAnswers);
      setActiveQuestion(index);
    }
  };
  return (
    <div className="container d-flex flex-column justify-content-center align-items-center whatCanJhoticaDo">
      <div className="text-center">
        <span className="title mb-3 mt-5">
          What Can Jhotika
          <br className="d-md-none" /> Do For You?
        </span>
      </div>

      <div
        className={`row mt-5 ${
          screenWidth <= 500 ? "flex-column-reverse" : ""
        }`}
      >
        <div className={`col-md-6  ${screenWidth <= 767 ? "px-6 mb-5" : ""}`}>
          <img src={Spain} className="img-fluid " alt="Spain" />
        </div>
        <div
          className={`col-md-6 mt-3 mt-md-0   ${
            screenWidth <= 767 ? "px-6" : ""
          }`}
        >
          <div className="d-flex flex-column">
            <span className="power">POWER FEATURE</span>
            <span className="PowerTitle">
              Powerful Algorithm for your visa info.
            </span>
            <span className="description">
              Our proprietary Visa algorithms leverages latest data to instantly
              predict visa needs based on your passport and destination,
              transforming the way you prepare for your international trips
            </span>
            <div>
              <button
                className="btn btn-primary rounded-pill mt-3"
                onClick={handleScrollToDownloadSection}
              >
                <span className="BTNText">Join Us Now</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div
          className={`col-md-6 pr-md-6  ${
            screenWidth <= 767 ? "px-6 mb-5" : ""
          }`}
        >
          <div className="d-flex flex-column">
            <span className="power">POWER FEATURE</span>
            <span className="PowerTitle">
              Secure, Organized Travel Documents – Jhotika Drive
            </span>
            <span className="description">
              Never lose important documents again – IDs, tickets, itineraries
              and more. Travel confidently, knowing everything's accessible, all
              the time - only to you.
            </span>
            <div>
              <button
                className="btn btn-primary rounded-pill mt-3"
                onClick={() => {
                  window.open(
                    "https://jhotika.com/privacy-policy.html",
                    "_blank"
                  );
                }}
              >
                <span className="BTNText">See Our Privacy Policy</span>
              </button>
            </div>
          </div>
        </div>
        <div className={`col-md-6  ${screenWidth <= 767 ? "px-6" : ""}`}>
          <img src={Tour} className="img-fluid" alt="Tour" />
        </div>
      </div>

      <div
        className={`row mt-5 ${
          screenWidth <= 500 ? "flex-column-reverse" : ""
        }`}
      >
        <div className={`col-md-6  ${screenWidth <= 767 ? "px-6 mb-5" : " "}`}>
          <img src={Congrats} className="img-fluid" alt="Congrats" />
        </div>
        <div
          className={`col-md-6  mt-md-0   ${screenWidth <= 767 ? "px-6" : ""}`}
        >
          <div className="d-flex flex-column">
            <span className="power">POWER FEATURE</span>
            <span className="PowerTitle">
              Share cards, Celebrate Adventures with Jhotika
            </span>
            <span className="description">
              Unlock new country badge, celebrate a complete profile, and
              showcase your passport strength. Special badges await those with
              multiple passports. Turn your travel milestones into playful
              rewards!
            </span>
            <div>
              <button
                className="btn btn-primary rounded-pill mt-3"
                onClick={handleScrollToDownloadSection}
              >
                <span className="BTNText">Explore More</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div
          className={`col-md-6 pr-md-6 ${
            screenWidth <= 767 ? "px-6 mb-5" : ""
          }`}
        >
          <div className="d-flex flex-column ">
            <span className="power">POWER FEATURE</span>
            <span className="PowerTitle">
              Pack Smart, Plan Smarter: Jhotika To-Dos & Packing
            </span>
            <span className="description">
              Simplify your travel prep with Jhotika's to-do and packing lists.
              Create detailed to-dos, build custom packing lists, and reuse them
              with our handy preset feature. Stress-free packing starts here!
            </span>
            <div>
              <div>
                <button
                  className="btn btn-primary rounded-pill mt-3"
                  onClick={handleScrollToDownloadSection}
                >
                  <span className="BTNText">Get in Touch</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={`col-md-6  ${screenWidth <= 767 ? "px-6" : ""}`}>
          <img src={ToDo} className="img-fluid" alt="ToDo" />
        </div>
      </div>

      {/*Budget*/}
      <div
        className={`row mt-5 ${
          screenWidth <= 500 ? "flex-column-reverse" : ""
        }`}
      >
        <div className={`col-md-6 ${screenWidth <= 767 ? "px-6 mb-5" : " "}`}>
          <img src={Budget} className="img-fluid" alt="Budget" />
        </div>
        <div className={`col-md-6   ${screenWidth <= 767 ? "px-6" : ""}`}>
          <div className="d-flex flex-column">
            <span className="power">POWER FEATURE</span>
            <span className="PowerTitle">
              Plan your dream trip, track your spending, & stay on budget.
            </span>
            <span className="description">
              Keep track of your spending on the go, ensuring your budget stays
              in check. Jhotika empowers you to experience the world your way,
              without financial surprises.
            </span>
            <div>
              <button
                className="btn btn-primary rounded-pill mt-3"
                onClick={handleScrollToDownloadSection}
              >
                <span className="BTNText">Explore More</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*End Of Budget*/}
      {/*Top BreakFast Place*/}

      <div className="row mt-5">
        <div
          className={`col-md-6 pr-md-6  ${
            screenWidth <= 767 ? "px-6 mb-5" : " mt-6"
          }`}
        >
          <div className="d-flex flex-column ">
            <span className="power">POWER FEATURE</span>
            <span className="PowerTitle">
              Jhotika knows the best places to eat, any time of day.
            </span>
            <span className="description">
              Jhotika knows the perfect places to eat, any time of day. Find
              top-rated coffee shops, breakfast spots, lunch places, and dinner
              destinations near you. Let Jhotika be your local food guide!
            </span>
            <div>
              <button
                className="btn btn-primary rounded-pill mt-3"
                onClick={handleScrollToDownloadSection}
              >
                <span className="BTNText">Get in Touch</span>
              </button>
            </div>
          </div>
        </div>
        <div className={`col-md-6   ${screenWidth <= 767 ? "px-6 " : ""}`}>
          <img src={Eating_Place} className="img-fluid" alt="Eating Place" />
        </div>
      </div>

      {/* END of Top BreakFast Place*/}

      <div
        className={`row mt-5 ${
          screenWidth <= 500 ? "flex-column-reverse" : ""
        }`}
      >
        <div
          className={`col-md-6 pr-md-6  ${screenWidth <= 767 ? "px-5" : ""}`}
        >
          <img src={VisaInfo} className="img-fluid" alt="Visa Info" />
        </div>
        <div className={`col-md-6   ${screenWidth <= 767 ? "px-6 mt-5" : ""}`}>
          <div className="d-flex flex-column">
            <div className="box">
              <span className="box-text">All emergency numbers together</span>
            </div>
            <div className="box-lg mt-3">
              <div className="justify-content-start text-start">
                <span className="box-lg-title">
                  Best Visa Information at Your Fingertips
                </span>
              </div>

              <div className="justify-content-start text-start">
                <span className="box-lg-des">
                  Discover countries offering convenient on-arrival visas, and
                  get ahead of the planning process with a personalized list of
                  countries where a pre-arranged visa is necessary.
                </span>
              </div>
              <div className="justify-content-start text-start">
                <button
                  className="btn btn-primary rounded-pill mt-3"
                  onClick={handleScrollToDownloadSection}
                >
                  <span className="BTNText">Explore Our App</span>
                </button>
              </div>
            </div>
            <div className="box mt-3">
              <span className="box-text">Find Airport Wi-fi</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row smartTravel p-4" ref={downloadSectionRef}>
        <div className="col-md-6">
          <div className="d-flex flex-column mt-4">
            <span className="PowerTitle">
              Ready for smarter travel? Download Jhotika now..
            </span>
            <span className="description">
              Download Jhotika for smarter, stress-free travel. Plan adventures,
              find top-rated restaurants, manage expenses, earn badges, and
              access essential travel info – all in one app.
            </span>
            <div className="d-flex mt-5">
              <a
                href={APP_STORE_URL}
                target="_blank"
                rel="noreferrer"
                className=" btn-outline-none p-0 btn-hover-effect"
              >
                <img src={AppStoreBtn} alt="App Store" className="img-fluid" />
              </a>
              <a
                href={GOOGLE_PLAY_URL}
                target="_blank"
                rel="noopener noreferrer"
                className=" btn-outline-none p-0 ml-3 btn-hover-effect"
              >
                <img src={GooglePlay} alt="Google Play" className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 pl-md-5">
          <img src={SmartTravel} className="img-fluid" alt="Smart Travel" />
        </div>
      </div>

      <div className="container mt-5 px-7">
        <div className="row">
          <div className="col-12 col-md-auto">
            <div className=" p-2">
              <span className="power">Badge</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md">
              <div className=" p-2">
                <span className="BadgesTitle">Unlock All Your Badges</span>
              </div>
            </div>
            <div className="col-12 col-md-auto d-flex justify-content-end">
              <div className="BadgesCount p-2 mt-4 text-center">
                <span className="">and 27 more badges</span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md">
            <div className="d-flex flex-wrap justify-content-center">
              <img
                src={profileBadge}
                alt="Profile Badge"
                className="img-fluid  mr-4 mb-2"
              />
              <img
                src={visitedOneCountryBadge}
                alt="Visited One Country Badge"
                className="img-fluid rounded-pill mr-2 mb-2"
              />
              <img
                src={visitedFiftyCountryBadge}
                alt="Visited Fifty Country Badge"
                className="img-fluid rounded-pill mr-2 mb-2"
              />
              <img
                src={visitedHundredCountryBadge}
                alt="Visited Hundred Country Badge"
                className="img-fluid  mb-2"
              />
            </div>
          </div>
        </div>
      </div>

      <div className=" row mt-7">
        <div className="col-12 col-md d-flex justify-content-center">
          <div className=" p-2">
            <span className="power">FAQs</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md d-flex justify-content-center">
            <div className=" p-2 text-center">
              <span className="PowerTitle">Frequently Asked Questions</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <span className="powerText">
              Find quick answers and helpful
              <br className="d-md-none" /> tips about Jhotika in our FAQ.
            </span>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div className="FAQ_Box justify-content-center mt-5">
                <div className="row">
                  {faqs.map((faq, index) => (
                    <div className="col-12" key={index}>
                      <div className="question1 p-3">
                        <a
                          className=" btn-outline-none "
                          style={{ cursor: "pointer" }}
                          onClick={() => toggleAnswer(index)}
                        >
                          {index === activeQuestion ? (
                            <img
                              src={FAQCROSS}
                              className="img-fluid pr-3"
                              alt="FAQ Plus Icon"
                            />
                          ) : (
                            <img
                              src={FAQPLUS}
                              className="img-fluid pr-3"
                              alt="FAQ Plus Icon"
                            />
                          )}

                          <span
                            className={
                              index === activeQuestion
                                ? "FAQ_Question_active"
                                : "FAQ_Question_normal"
                            }
                          >
                            {faq.question}
                          </span>
                        </a>
                      </div>
                      {showAnswers[index] && (
                        <div
                          className="answer1 pl-5 FAQ_Answer"
                          dangerouslySetInnerHTML={{ __html: faq.answer }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-7">
        <div className="col-12 col-md d-flex justify-content-center">
          <div className=" p-2">
            <span className="power">ANALYTICS</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <div className="p-2 text-center">
              <span className="BadgesTitle">
                Join the Smart Travel Revolution
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 col-md mt-4">
          <div className="d-flex flex-wrap justify-content-around">
            <div className="col-md-4 ">
              <div>
                <span className="Analytics_count d-flex justify-content-center">
                  <div>
                    <CountUp start={0} end={34} duration={3} />
                  </div>
                </span>
                <br />
              </div>
              <div>
                <span className="power  d-flex justify-content-center">
                  Unique Badges
                </span>
                <br />
              </div>
            </div>
            <div className="col-md-4 ">
              <div>
                <span className="Analytics_count d-flex justify-content-center">
                  <div>
                    <CountUp start={0} end={200} duration={3} />
                  </div>
                  M
                </span>
                <br />
              </div>
              <div>
                <span className="power  d-flex justify-content-center">
                  Location Data Points
                </span>
                <br />
              </div>
            </div>
            <div className="col-md-4 ">
              <div>
                <span className="Analytics_count d-flex justify-content-center">
                  <div>
                    <CountUp start={0} end={246} duration={3} />
                  </div>
                </span>
                <br />
              </div>
              <div>
                <span className="power  d-flex justify-content-center">
                  countries’ & territories’ visa info
                </span>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatCanJhoticaDo;
