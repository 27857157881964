import { actionActions } from "../actions/actionActions";
import {
  getSuccessful,
  getKey,
  getNonSuccessful,
} from "../actions/actionUtils";

export const DEFAULT_REDUCER = [];

const successfulActionsReducers = (state = DEFAULT_REDUCER, action) => {
  const actionType = action.type;
  if (action.type === actionActions.CLEAR_ALL_ACTIONS_REQUESTED) {
    return [...state].filter((item) => action.payload.actionKey !== item);
  } else if (getNonSuccessful(actionType)) {
    return [...state].filter((item) => getKey(actionType) !== item);
  } else if (getSuccessful(actionType)) {
    return [...state, getKey(actionType)];
  }
  return state;
};

export default successfulActionsReducers;
