import { call, takeLatest, delay } from "redux-saga/effects";

import { profileActions } from "../redux/actions/profileActions";
import * as service from "../services/index";
import * as firebaseStorageServices from "../services/firebaseStorageServices";
import { catchEmpties, sagaError, sagaSuccess } from "./sagaUtils";

export function* updateBioWorker(action) {
  yield delay(1000);
  const data = action.payload;
  const errorMessage = catchEmpties(data);
  if (errorMessage) {
    yield sagaError(profileActions.UPDATE_BIO_FAILED, new Error(errorMessage));
  } else {
    try {
      const got = yield call(
        service.fetchIdTokenAndExecute,
        service.updateJtmProfile,
        action.payload
      );
      yield sagaSuccess(profileActions.UPDATE_BIO_SUCCESSFUL, {
        ...got,
      });
    } catch (e) {
      yield sagaError(profileActions.UPDATE_BIO_FAILED, e);
    }
  }
}
export function* updateUsernameWorker(action) {
  yield delay(1000);
  const data = action.payload;
  const errorMessage = catchEmpties(data);
  if (errorMessage) {
    yield sagaError(
      profileActions.UPDATE_USERNAME_FAILED,
      new Error(errorMessage)
    );
  } else {
    try {
      const got = yield call(
        service.fetchIdTokenAndExecute,
        service.updateJtmProfile,
        action.payload
      );
      yield sagaSuccess(profileActions.UPDATE_USERNAME_SUCCESSFUL, {
        ...got,
      });
    } catch (e) {
      yield sagaError(profileActions.UPDATE_USERNAME_FAILED, e);
    }
  }
}

export function* updateNameWorker(action) {
  yield delay(1000);
  const data = action.payload;
  const errorMessage = catchEmpties(data);
  if (errorMessage) {
    yield sagaError(profileActions.UPDATE_NAME_FAILED, new Error(errorMessage));
    return;
  }
  try {
    const got = yield call(service.updateUserFullname, action.payload);
    yield sagaSuccess(profileActions.UPDATE_NAME_SUCCESSFUL, { profile: got });
  } catch (e) {
    yield sagaError(profileActions.UPDATE_NAME_FAILED, e);
  }
}

export function* updateEmailWorker(action) {
  yield delay(1000);
  const data = action.payload;
  const errorMessage = catchEmpties(data);
  if (errorMessage) {
    yield sagaError(
      profileActions.UPDATE_EMAIL_FAILED,
      new Error(errorMessage)
    );
    return;
  }
  try {
    const got = yield call(service.updateUserEmail, action.payload);
    yield sagaSuccess(profileActions.UPDATE_EMAIL_SUCCESSFUL, {
      profile: got,
    });
  } catch (e) {
    yield sagaError(profileActions.UPDATE_EMAIL_FAILED, e);
  }
}

export function* updateProfileImageWorker(action) {
  yield delay(500);
  const data = action.payload;
  const errorMessage = catchEmpties(data);
  if (errorMessage) {
    yield sagaError(
      profileActions.UPDATE_PROFILE_IMAGE_FAILED,
      new Error(errorMessage)
    );
    return;
  }
  try {
    const got = yield call(
      firebaseStorageServices.updateProfileImage,
      action.payload
    );
    yield sagaSuccess(profileActions.UPDATE_PROFILE_IMAGE_SUCCESSFUL, {
      profile: got,
    });
  } catch (e) {
    yield sagaError(profileActions.UPDATE_PROFILE_IMAGE_FAILED, e);
  }
}

export function* updateStatusWorker(action) {
  yield delay(500);
  const data = action.payload;
  const errorMessage = catchEmpties(data);
  if (errorMessage) {
    yield sagaError(
      profileActions.UPDATE_STATUS_FAILED,
      new Error(errorMessage)
    );
    return;
  }
  try {
    const got = yield call(
      service.fetchIdTokenAndExecute,
      service.updateStatus,
      action.payload
    );

    yield sagaSuccess(profileActions.UPDATE_STATUS_SUCCESSFUL, {
      status: got,
    });
  } catch (e) {
    yield sagaError(profileActions.UPDATE_STATUS_FAILED, e);
  }
}

export function* updateCurrentCityWorker(action) {
  yield delay(500);
  try {
    const got = yield call(
      service.fetchIdTokenAndExecute,
      service.updateCurrentCity,
      action.payload
    );

    yield sagaSuccess(profileActions.UPDATE_CURRENT_CITY_SUCCESSFUL, {
      currentCity: got,
    });
  } catch (e) {
    yield sagaError(profileActions.UPDATE_CURRENT_CITY_FAILED, e);
  }
}

export function* deleteAccountWorker(action) {
  yield delay(500);
  const data = action.payload;
  const errorMessage = catchEmpties(data);
  if (errorMessage) {
    yield sagaError(
      profileActions.DELETE_ACCOUNT_FAILED,
      new Error(errorMessage)
    );
    return;
  }
  try {
    const got = yield call(
      service.fetchIdTokenAndExecute,
      service.deleteAccount,
      action.payload
    );
    yield sagaSuccess(profileActions.DELETE_ACCOUNT_SUCCESSFUL, {
      profile: got,
    });
  } catch (e) {
    yield sagaError(profileActions.DELETE_ACCOUNT_FAILED, e);
  }
}

export function* profileSagas() {
  yield takeLatest(profileActions.UPDATE_BIO_REQUESTED, updateBioWorker);
  yield takeLatest(
    profileActions.UPDATE_USERNAME_REQUESTED,
    updateUsernameWorker
  );
  yield takeLatest(profileActions.UPDATE_NAME_REQUESTED, updateNameWorker);
  yield takeLatest(profileActions.UPDATE_EMAIL_REQUESTED, updateEmailWorker);
  yield takeLatest(
    profileActions.UPDATE_PROFILE_IMAGE_REQUESTED,
    updateProfileImageWorker
  );
  yield takeLatest(profileActions.UPDATE_STATUS_REQUESTED, updateStatusWorker);

  yield takeLatest(
    profileActions.UPDATE_CURRENT_CITY_REQUESTED,
    updateCurrentCityWorker
  );

  yield takeLatest(
    profileActions.DELETE_ACCOUNT_REQUESTED,
    deleteAccountWorker
  );
}
