import { StatsigProvider } from "statsig-react";

import store from "./redux/store/index";
import RouterProviderContainer from "./ui/pages/router";
import { Provider } from "react-redux";
import "./ui/css/Section.css";


const STATSIG_CLIENT_SDK_KEY =
  "client-4H29tCLFrCWyQ3zuhiNzk9wPGv5NC4W2Tes1pOo9JRm";

function App() {
  const user = {};
  return (
    <Provider store={store}>
          <StatsigProvider
            sdkKey={STATSIG_CLIENT_SDK_KEY}
            waitForInitialization={true}
            user={user}
          >
            <RouterProviderContainer />
          </StatsigProvider>
    </Provider>
  );
}

export default App;
