import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import editStatusIcon from '../../../assets/images/edit-status.svg';

import '../../../css/Profile.css';
import { userDataSelector } from '../../../../selectors/user';
import AvailabilityStatusEditModal from './AvailabilityStatusEditModal';

export const AVAILABILITY_STATUS = {
  0: {
    idx: 0,
    name: 'Available',
    color: '#259F46',
  },
  1: {
    idx: 1,
    name: 'Sporadic',
    color: '#FFC83C',
  },
  2: {
    idx: 2,
    name: 'Unavailable',
    color: '#FF7245',
  },
};

export const AvailabilityStatus = (props) => {
  const availabilityStatus = AVAILABILITY_STATUS[props.status.availability];
  const isViewerUser = props.viewerData?.uid === props.status.userUuid;
  const [showEditStatusModal, setShowEditStatusModal] = useState(false);
  return (
    <div>
      <p style={{ marginBottom: 0 }}>
        <span
          className="dot"
          style={{ backgroundColor: availabilityStatus.color }}
        />
        &nbsp;
        <span style={{ color: availabilityStatus.color, fontSize: '13px' }}>
          {availabilityStatus.name}{' '}
          {isViewerUser ? (
            <EditButon setShowEditStatusModal={setShowEditStatusModal} />
          ) : null}
        </span>
      </p>
      <span style={{ fontSize: '13px' }}>Status:</span> &nbsp;
      <span
        style={{
          backgroundColor: '#EBF0FF',
          borderRadius: '4px',
          border: '1px solid #356AFC',
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: 3,
          paddingRight: 5,

          fontSize: '13px',
        }}
      >
        {props.status.title}
      </span>
      <p style={{ fontSize: '13px' }}>{props.status.note}</p>
      <AvailabilityStatusEditModal
        status={props.status}
        show={showEditStatusModal}
        onHide={() => setShowEditStatusModal(false)}
      />
    </div>
  );
};

const EditButon = (props) => {
  return (
    <img
      src={editStatusIcon}
      alt="Edit Status"
      onClick={() => props.setShowEditStatusModal(true)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    viewerData: userDataSelector(state),
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(AvailabilityStatus);
