

const WelcomeCard = ({ welcomeText }) => (
  <div className="top-text d-flex justify-content-center justify-content-md-start">
    <div
      className="text-holder"
      style={{ width: "200px", height: "92px" }}
    >
      <p className="text-small">{welcomeText}</p>
      <p className="text-big">JHOTIKA</p>
    </div>
  </div>
);

export default WelcomeCard;
