import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFullNameFromIso2 } from "./CountryData";
import { validateInput } from "./DestinationCountryUtil";
import { CountryForm } from "./CountryForm";
import { handleSubmit, getData } from "./DestinationCountryUtil";
import { Alert } from "react-bootstrap";

const DestinationCountry = (props) => {
  const iso2 = useParams().iso2;
  if (iso2 === null) {
    return <div>Invalid country</div>;
  }
  return (
    <div>
      <div style={{ textAlign: "center", marginBottom: 40 }}>
        <h4>Destination Country - {getFullNameFromIso2(iso2)}</h4>
      </div>

      <div className="d-flex flex-row" style={{ marginTop: 20 }}>
        <Input iso2={iso2} />
      </div>
    </div>
  );
};

const Input = (props) => {
  const [visaFreeCountries, setVisaFreeCountries] = useState("");
  const [onArrivalCountries, setOnArrivalCountries] = useState("");
  const [visaRequiredCountries, setVisaRequiredCountries] = useState("");
  const [dependencyVisaCountries, setDependencyVisaCountries] = useState("");
  const [eVisaCountries, setEVisaCountries] = useState("");

  const [note, setNote] = useState("");
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [requestSuccessful, setRequestSuccessful] = useState(null);

  useEffect(() => {
    const invalidNames = [];
    [visaFreeCountries, onArrivalCountries, visaRequiredCountries].forEach(
      (countries) => {
        invalidNames.push(...validateInput(countries).invalidCountries);
      }
    );
    if (invalidNames.length === 0) {
      setIsSubmitButtonEnabled(true);
    } else {
      setIsSubmitButtonEnabled(false);
    }
  }, [visaFreeCountries, onArrivalCountries, visaRequiredCountries]);

  useEffect(() => {
    getData(props.iso2).then((data) => {
      if (data === null) {
        return;
      } else {
        console.log("got: ", data);
      }
      setVisaFreeCountries(data.visaFreeCountries);
      setOnArrivalCountries(data.onArrivalCountries);
      setVisaRequiredCountries(data.visaRequiredCountries);
      setDependencyVisaCountries(data.dependencyVisaCountries);
      setEVisaCountries(data.eVisaCountries);
      setNote(data.note);
    });
  }, [props.iso2]);

  return (
    <div style={{ width: 800 }}>
      <SuccessMessage requestSuccessful={requestSuccessful} />
      <div style={{}} className="d-flex flex-column">
        <CountryForm
          key={props.iso2 + "visaFreeCountries23"}
          countries={visaFreeCountries}
          label="Visa Free Countries"
          callback={(s) => {
            if (s !== visaFreeCountries) {
              setVisaFreeCountries(s);
            }
          }}
        />
      </div>
      <div style={{ marginTop: 40 }} className="d-flex flex-column">
        <CountryForm
          key={props.iso2 + "onArrivalCountries"}
          countries={onArrivalCountries}
          label="On Arrival Countries"
          callback={(s) => {
            if (s !== onArrivalCountries) {
              setOnArrivalCountries(s);
            }
          }}
        />
      </div>
      <div style={{ marginTop: 40 }} className="d-flex flex-column">
        <CountryForm
          key={props.iso2 + "visaRequiredCountries"}
          countries={visaRequiredCountries}
          label="Visa Required From Countries"
          callback={(s) => setVisaRequiredCountries(s)}
        />
      </div>
      <div style={{ marginTop: 40 }} className="d-flex flex-column">
        <CountryForm
          key={props.iso2 + "dependencyVisaCountries"}
          countries={dependencyVisaCountries}
          label="Dependency Visa Countries"
          callback={(s) => setDependencyVisaCountries(s)}
        />
      </div>
      <div style={{ marginTop: 40 }} className="d-flex flex-column">
        <CountryForm
          key={props.iso2 + "eVisaCountries"}
          countries={eVisaCountries}
          label="E-Visa allowed for passports from Countries"
          callback={(s) => setEVisaCountries(s)}
        />
      </div>
      <div style={{ marginTop: 40 }} className="d-flex flex-column">
        <h5>Note</h5>
        <textarea
          value={note}
          rows={3}
          style={{ width: 700 }}
          onChange={(e) => {
            if (note !== e.target.value) {
              setNote(e.target.value);
            }
          }}
        />
      </div>

      <SuccessMessage requestSuccessful={requestSuccessful} />

      <div style={{ width: 700, textAlign: "center", marginTop: 20 }}>
        <button
          disabled={!isSubmitButtonEnabled}
          onClick={() => {
            setRequestSuccessful(null);
            handleSubmit(
              props.iso2,
              visaFreeCountries,
              onArrivalCountries,
              visaRequiredCountries,
              dependencyVisaCountries,
              eVisaCountries,
              note,
              "PUBLISHED"
            ).then((resp) => {
              if (resp === true) {
                setRequestSuccessful(true);
                setTimeout(() => window.location.reload(), 3000);
              } else {
                setRequestSuccessful(false);
              }
            });
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

const SuccessMessage = (props) => {
  if (props.requestSuccessful === null) {
    return null;
  }
  return (
    <div
      style={{
        marginTop: 30,
        width: 700,
        display: "flex",
        justifyContent: "center",
      }}
    >
      {props.requestSuccessful ? (
        <Alert
          variant="success"
          show={props.requestSuccessful}
          style={{ width: 350 }}
        >
          Data saved successfully - refreshing page
        </Alert>
      ) : (
        <Alert
          variant="danger"
          show={props.requestSuccessful === false}
          style={{ width: 350 }}
        >
          Failed to save data. Ask Taman for help
        </Alert>
      )}
    </div>
  );
};

export default DestinationCountry;
