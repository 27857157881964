import {Outlet, createBrowserRouter, RouterProvider, useLocation} from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { useGate } from "statsig-react";

import "../css/Root.css";
import Home from "./Home";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import ForgotPassword from "./auth/ForgotPassword";
import Auth from "./auth/index";
import ProfileContainer, { loader as ProfileLoader } from "./user/Profile";
import { NotFound as AuthNotFound } from "./auth/NotFound";
import NotFound from "./misc/NotFound";
import { NotFoundCore } from "./misc/NotFound";
import ActionsPage from "./auth/ActionsPage";
import NavbarContainer from "../components/NavbarContainer";
import AuthContainer from "../pages/auth/AuthContainer";
import Footer from "../components/Footer";
import ChatSigned from "./ChatSigned";

import { userDataSelector, userSignedInSelector } from "../../selectors/user";
import { loader as ViewerProfileLoader } from "./user/Profile";
import ProfileSettingsContainer from "./user/ProfileSettingsContainer";
import DeletionPolicy from "./policies/DeletionPolicy";
import NavbarAdmin from "../components/NavbarContainerAdmin";
import CountriesContainer from "./admin/visa/CountriesContainer";
import DestinationCountry from "./admin/visa/DestinationCountry";


const RootCantainer = () => {
  const { value, isLoading } = useGate("web-router-v1");
  const routingEnabled = !isLoading && value;

  // Determine whether to show the navbar and footer based on the current route

  return (
    <div className="d-flex flex-column min-vh-100 align-items-center justify-content-between">
      <div>
        { routingEnabled ? null : <NavbarContainer />}
        <Outlet />
      </div>
      { <Footer className="mt-auto" />}
    </div>
  );
};

const AdminRootContainer = () => {
  return (
    <div className="d-flex flex-column min-vh-100 align-items-center justify-content-between">
      <div>
        <NavbarAdmin />
        <div className="d-flex flex-column min-vh-100 align-items-center justify-content-between">
          <Outlet />
        </div>
      </div>
      <Footer className="mt-auto" />
    </div>
  );
};

const RouterProviderContainer = (props) => {
  const router = createBrowserRouter([
    {
      path: "/admin",
      element: <AdminRootContainer />,
      children: [
        {
          path: "/admin",
          element: <CountriesContainer />,
        },
        {
          path: "/admin/visa/:iso2",
          element: <DestinationCountry />,
        },
      ],
    },
    {
      path: "/",
      element: <RootCantainer />,
      errorElement: <NotFound />,
      children: [
        {
          path: "/",
          element: props.viewerSignedIn ? <ProfileContainer /> : <Home />,
          errorElement: <NotFound />,
          loader: async (request, params) => {
            return props.viewerSignedIn
              ? ProfileLoader({
                  request,
                  params,
                  viewerData: props.viewerData,
                })
              : null;
          },
        },

        {
          path: "/auth",
          element: <AuthContainer />,
          children: [
            {
              path: "/auth/action",
              element: <ActionsPage />,
            },
            {
              path: "/auth/forgotPassword",
              element: <ForgotPassword />,
            },
            {
              path: "/auth",
              element: <Auth />,
              children: [
                {
                  path: "/auth/signin",
                  element: <SignIn />,
                },
                {
                  path: "/auth/signup",
                  element: <SignUp />,
                },
                {
                  path: "/auth/",
                  element: <AuthNotFound />,
                  index: true,
                },
              ],
            },
          ],
          errorElement: <NotFound />,
        },
        {
          path: "/user/settings",
          element: props.viewerSignedIn ? (
            <ProfileSettingsContainer />
          ) : (
            <NotFoundCore />
          ),
          loader: async (request, params) =>
            ViewerProfileLoader({
              request,
              params,
              viewerData: props.viewerData,
            }),
        },
        {
          path: "/user/:username",
          element: <ProfileContainer />,
          loader: ProfileLoader,
        },
        {
          path: "/chat",
          element: <ChatSigned />,
          errorElement: <NotFound />,
        },
        {
          path: "/deletion-policy",
          element: <DeletionPolicy />,
        },
        {
          path: "/signout",
          element: null,
          errorElement: <NotFound />,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

function mapStateToProps(state) {
  return {
    viewerSignedIn: userSignedInSelector(state),
    viewerData: userDataSelector(state),
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(RouterProviderContainer);
