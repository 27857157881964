import { Navbar as BootstrapNavbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../assets/images/logo.png";
import { Link, NavLink } from "react-router-dom";
import "../css/Navbar.css";

const NavbarSignedOut = (props) => {
  return (
    <BootstrapNavbar
      expand="sm"
      className="main-nav-holder w-100 justify-content-between"
      style={{ fontFamily: "Poppins" }}
    >
      <BootstrapNavbar.Brand>
        <Link className="nav-link" to="/">
          <img src={Logo} className="logo" alt="Jhotika logo" />
        </Link>
      </BootstrapNavbar.Brand>

      <div className="nav-wrapper">
        <BootstrapNavbar.Toggle aria-controls="basic-BootstrapNavbar-nav" />
        <BootstrapNavbar.Collapse
          id="basic-BootstrapNavbar-nav "
          className="justify-content-end"
        >
          <Nav className="mr-auto">
            <Nav.Item>
              <NavLink className="nav-link" to="/">
                Home
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link" to="/auth/signin">
                Login
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link" to="/auth/signup">
                Sign Up
              </NavLink>
            </Nav.Item>
          </Nav>
        </BootstrapNavbar.Collapse>
      </div>
    </BootstrapNavbar>
  );
};

export default NavbarSignedOut;
