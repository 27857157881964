import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { userSignedInSelector } from "../../selectors/user";
import NavbarSignedIn from "./NavbarSignedIn";
import NavbarSignedOut from "./NavbarSignedOut";

const NavbarContainer = (props) => {
  return props.viewerSignedIn ? <NavbarSignedIn /> : <NavbarSignedOut />;
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

function mapStateToProps(state) {
  return {
    viewerSignedIn: userSignedInSelector(state),
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(NavbarContainer);
