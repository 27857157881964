export const schengenVisaCountries = [
  "Austria",
  "Belgium",
  "Croatia",
  "Czechia / Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Iceland",
  "Italy",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
];

export const overseasFranceCountries = [
  "French Guiana",
  "Guadeloupe",
  "Martinique",
  "Mayotte",
  "Réunion",
  "Saint Pierre and Miquelon",
  "Saint Barthélemy",
  "Saint Martin",
  "Wallis and Futuna",
  "French Polynesia",
  "New Caledonia",
];

export const overseasBritishCountries = [
  "Anguilla",
  "Bermuda",
  "British Virgin Islands",
  "Cayman Islands",
  "Falkland Islands",
  "Gibraltar",
  "Montserrat",
  "Pitcairn Islands",
  "Saint Helena",
  "Turks and Caicos Islands",
];

export const overseasDutchCountries = [
  "Aruba",
  "Curaçao",
  "Sint Maarten",
  "Caribbean Netherlands",
];
