export const actionActions = {
  CLEAR_ALL_ACTIONS_REQUESTED: "CLEAR_ALL_ACTIONS_REQUESTED",
};

export const handleClearAllActionsOfType = (actionKey) => {
  return {
    type: actionActions.CLEAR_ALL_ACTIONS_REQUESTED,
    payload: { actionKey },
  };
};
