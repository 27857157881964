import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { userSignedInSelector } from "../../../selectors/user";

import SignedInRedirect from "./SignedInRedirect";
import NavbarContainer from "../../components/NavbarContainer";


import "../../css/Auth.css";

import { useGate } from "statsig-react";
import { Outlet } from "react-router-dom";

const AuthContainer = (props) => {
  const { value, isLoading } = useGate("web-router-v1");
  const routingEnabled = !isLoading && value;
  return props.viewerSignedIn ? (
    <SignedInRedirect />
  ) : (
    <>
      {!routingEnabled ? null: <NavbarContainer /> }
      <Outlet />
    </>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

function mapStateToProps(state) {
  return {
    viewerSignedIn: userSignedInSelector(state),
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(AuthContainer);
