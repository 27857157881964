import { useEffect, useState } from "react";

import { updateUserPassword } from "../../../services";

import { Modal, Button, Spinner } from "react-bootstrap";

const PasswordModal = (props) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleError = (error) => {
    setError(error);
    setLoading(false);
    setSuccess(false);
  };
  const handleSuccess = () => {
    setError(null);
    setLoading(false);
    setSuccess(true);
  };

  const handleLoading = () => {
    setError(null);
    setLoading(true);
    setSuccess(false);
  };

  const handleClearMessages = () => {
    setError(null);
    setLoading(false);
    setSuccess(false);
  };

  const handleChangePassword = () => {
    handleLoading();

    updateUserPassword(
      oldPassword,
      newPassword,
      handleLoading,
      handleSuccess,
      handleError
    );
  };

  useEffect(() => {
    // password validation
    handleClearMessages();
    if (!newPassword && !confirmPassword && !oldPassword) {
      setError(null);
    } else if (
      (newPassword || oldPassword) &&
      newPassword !== confirmPassword
    ) {
      setError("New password and confirm password must match.");
      setPasswordsMatch(false);
    } else if (newPassword.length < 6) {
      setError("Password must be at least 6 characters long.");
    } else if (oldPassword && oldPassword === newPassword) {
      setError("New password must be different from old password.");
    } else {
      setPasswordsMatch(true);
      setError(null);
    }
  }, [oldPassword, newPassword, confirmPassword]);

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>
          <h5 className="mt-3">Change Password</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100">
          <form>
            {success === true && (
              <p className="text-success">Password successfully changed</p>
            )}
            {loading && (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            )}
            <input
              autoComplete="current-password"
              className="brea-input settingInput user-profile"
              disabled={loading}
              id="oldPassword"
              name="oldPassword"
              placeholder="Enter current password"
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <input
              autoComplete="new-password"
              className="brea-input settingInput user-profile"
              disabled={loading}
              id="newPassword"
              name="newPassword"
              placeholder="Enter new password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />

            <input
              autoComplete="new-password"
              disabled={loading}
              className={`brea-input user-profile ${
                passwordsMatch ? "" : "password-mismatch"
              }`}
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm new password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </form>
          {error && <p className="text-danger">{error}</p>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        {!error && oldPassword ? (
          <Button
            disabled={loading || success}
            variant="primary"
            type="submit"
            onClick={handleChangePassword}
          >
            Save Changes
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};
export default PasswordModal;
