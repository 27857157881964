import { useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { handleSignInSubmit } from "../../../redux/actions/authActions";

import "../../css/Auth.css";
import WelcomeCard from "./WelcomeCard";
import SocialLogin from "./SocialLogin";
import { NavLink } from "react-router-dom";
import ForgotPasswordCta from "./ForgotPasswordCta";
import Spinner from "../../components/Spinner";

import { isActionInflight } from "../../../selectors/inflightActions";

import ReduxErrorMessage from "../../components/ReduxErrorMessage";
import { validateEmail, validatePassword } from "../../../services/utils";
import { handleClearAllActionsOfType } from "../../../redux/actions/actionActions";

const ACTION_KEY = "SIGNIN";

const SignIn = (props: {
  isLoading: boolean,
  handleSignInSubmit: (email: string, password: string) => void,
  clearActions: (actionKey: string) => void,
}) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  return (
    <div className="form-holder">
      <WelcomeCard welcomeText="Welcome back to" />
      <form>
        <input
          autoComplete="email"
          className="brea-input"
          disabled={props.isLoading}
          id="email"
          name="email"
          placeholder="Enter your email"
          type="text"
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError("");
          }}
        />
        {emailError ? <p className="text-danger small">{emailError}</p> : null}
        <input
          autoComplete="current-password"
          className="brea-input"
          disabled={props.isLoading}
          id="password"
          name="password"
          placeholder="Enter password"
          type="password"
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordError("");
          }}
        />

        {passwordError ? (
          <p className="text-danger small">{passwordError}</p>
        ) : null}

        <ReduxErrorMessage actionKey={ACTION_KEY} />
        <button
          className="mt-3 btn btn-primary brea-button"
          disabled={props.isLoading || !email || !password}
          type="submit"
          onClick={(e) => {
            // clean up any previous errors
            props.clearActions(ACTION_KEY);
            setEmailError("");
            setPasswordError("");
            e.preventDefault();

            if (!validateEmail(email)) {
              setEmailError("Please enter a valid email address");
            }
            if (!validatePassword(password)) {
              setPasswordError("Password must be at least 6 characters long");
            }
            if (!validateEmail(email) || !validatePassword(password)) {
              return;
            }
            props.handleSignInSubmit(email, password);
          }}
        >
          <Spinner actionKey={ACTION_KEY} />
          Log In
        </button>
      </form>
      <ForgotPasswordCta />
      <hr className="line-hr mt-3" />
      {/* <p
        style={{
          color: "#a2a2a2",
          fontWeight: "regular",
          textAlign: "center",
        }}
      >
        or
      </p>
      <SocialLogin /> */}
      <p
        className="text-center pt-4"
        style={{
          fontSize: ".9rem",
        }}
      >
        Don't have an account?{" "}
        <span
          style={{
            color: "#356AFC",
            textDecoration: "underline",
          }}
        >
          <NavLink to="/auth/signup"> Create an account</NavLink>
        </span>
      </p>
    </div>
  );
};

const matchStateToProps = (state) => ({
  isLoading: isActionInflight(state, ACTION_KEY),
});

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleSignInSubmit,
      clearActions: () => handleClearAllActionsOfType(ACTION_KEY),
    },
    dispatch
  );
};

export default connect(matchStateToProps, matchDispatchToProps)(SignIn);
