import React from "react";
import { Link } from "react-router-dom";
import "../css/Footer.css";

const Footer = () => {
  return (
    <footer className="footer-holder d-flex flex-wrap justify-content-between align-items-center p-4 mt-4 w-100">
      <Link className="nav-link" to="/">
        <h4 style={{ color: "#356afc", fontFamily: "Poppins" }}>
          <b>JHOTIKA</b>
        </h4>
        {/* <img src={Logo} className="logo" alt="Jhotika logo" /> */}
      </Link>

      <div className="d-flex flex-wrap justify-content-center align-items-center footer-link-holder">
        <a
          className="nav-item nowrap me-3 text-muted footer-text"
          href="/privacy-policy.html"
        >
          Privacy Policy
        </a>
        <a
          className="nav-item nowrap me-3 text-muted footer-text"
          href="/terms-and-conditions.html"
        >
          Terms & Conditions
        </a>
        <Link
          className="nav-item nowrap text-muted footer-text"
          to="/deletion-policy"
        >
          Deletion Policy
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
