import { authActions } from "../actions/authActions";
import { profileActions } from "../actions/profileActions";

const DEFAULT_REDUCER = {
  userData: {
    name: "",
    email: "",
    phone: "",
    bio: "",
  },
  signedIn: false,
};

export default function profileReducers(state = DEFAULT_REDUCER, action) {
  if (
    action.type === authActions.SIGNOUT_SUCCESSFUL ||
    action.type === authActions.SIGNOUT_REQUESTED ||
    action.type === profileActions.DELETE_ACCOUNT_SUCCESSFUL
  ) {
    return DEFAULT_REDUCER;
  }

  if (action.type === authActions.SIGNIN_SUCCESSFUL) {
    return {
      ...DEFAULT_REDUCER,
      signedIn: true,
      userData: action.payload,
    };
  }

  // Update profile
  if (
    action.type === profileActions.UPDATE_NAME_SUCCESSFUL ||
    action.type === profileActions.UPDATE_EMAIL_SUCCESSFUL ||
    action.type === profileActions.UPDATE_PROFILE_IMAGE_SUCCESSFUL ||
    action.type === profileActions.UPDATE_CURRENT_CITY_SUCCESSFUL
  ) {
    return {
      ...state,
      userData: { ...state.userData, ...action.payload.profile },
    };
  }

  if (action.type === profileActions.UPDATE_BIO_SUCCESSFUL) {
    return {
      ...state,
      userData: { ...state.userData, bio: action.payload.bio },
    };
  }

  if (action.type === profileActions.UPDATE_USERNAME_SUCCESSFUL) {
    return {
      ...state,
      userData: { ...state.userData, status: action.payload.username },
    };
  }
  return state;
}
