import React from "react";
import { Await, useLoaderData } from "react-router-dom";
import { defer } from "react-router-dom";
import "../../css/Profile.css";
import defaultProfilePic from "../../assets/images/userAvatar.png";
import { NotFoundCore } from "../misc/NotFound";
import pluralize from "pluralize";
import FullPageSpinner from "../misc/FullPageSpinner";
import AvailabilityStatus from "./status/AvailabilityStatus";
import { baseUrl } from "../../../services/utils";
import { genGetUserUuidFromUsername } from "../../../services";

export const loader = async (props) => {
  const apiUrl = `${baseUrl}/users/`;
  var userUuid;

  if (props.params?.username != null) {
    userUuid = await genGetUserUuidFromUsername(props.params.username);
  } else {
    userUuid = props.viewerData.uid;
  }
  if (!userUuid) {
    return defer({
      userDataPromises: Promise.reject("User not found"),
    });
  }
  const dataPromise = new Promise((resolve) => setTimeout(resolve, 300)) // This tiny delay is added intentionally to show the loading spinner
    .then(() => fetch(apiUrl + userUuid))
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response;
    })
    .then((data) => data.json())
    .catch((err) => {
      throw err;
    });

  const countriesVisitedPromise = fetch(`${baseUrl}/countries/user/${userUuid}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response;
    })
    .then((data) => data.json())
    .catch((err) => {
      throw err;
    });

  const statusPromise = fetch(`${baseUrl}/users/availabilityStatus/${userUuid}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response;
    })
    .then((data) => data.json())
    .catch((err) => {
      throw err;
    });

  return defer({
    userDataPromises: Promise.all([
      dataPromise,
      statusPromise,
      countriesVisitedPromise,
    ]),
  });
};

const ProfileContainer = () => {
  const data = useLoaderData();
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <Await resolve={data.userDataPromises} errorElement={<NotFoundCore />}>
        {([userData, status, countriesVisitedData]) => {
          const visitedCountries = countriesVisitedData?.countries ?? [];
          return (
            <Profile
              userData={userData}
              status={status}
              visitedCountries={visitedCountries}
            />
          );
        }}
      </Await>
    </React.Suspense>
  );
};

const Profile = ({ userData, userUuid, status, visitedCountries }) => {
  if (!userData) {
    return null;
  }
  const numCountries = visitedCountries.length ?? 0;

  return (
    <div className="ps-5 pt-5 pe-5 user-profile overflow-hidden">
      <div className="user-info d-flex align-items-start">
        <img
          className="userDP me-3"
          src={userData.profileImageUrl || defaultProfilePic}
          alt={`Avatar ${userData.fullName}`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = defaultProfilePic;
          }}
        />

        <div className="d-flex flex-row justify-content-between w-100 ">
          <div className="about-me">
            <p className="username mt-2">
              <b>{userData.fullName ? userData.fullName : "User"}</b>
            </p>
            <p style={{ fontFamily: "Poppins", fontSize: 12, fontWeight: 300 }}>
              {userData.currentCity}
            </p>
            <AvailabilityStatus status={status} />

            <p className="bio mt-2">{userData.bio || ""}</p>
          </div>
          <div className="about-badge text-center">
            <p className="badge-text">
              Explored {pluralize("country", numCountries, true)} <br />
            </p>
          </div>
        </div>
      </div>
      <hr className="my-4 w-75" style={{ color: "#A7A7A7" }} />
      <CountriesVisitedSection
        countries={visitedCountries}
        userData={userData}
      />
    </div>
  );
};

const CountriesVisitedSection = ({ userData, countries }) => {
  countries.sort((a, b) => a.country.localeCompare(b.country));
  return (
    <div className="country-list">
      <h5>
        {pluralize("country", countries.length)} Visited ({countries.length})
      </h5>
      <div className="d-flex mt-3 flex-wrap">
        {countries.map((country, index) => (
          <small className="text-center me-4 placeBox" key={index}>
            <img
              className="spot-img"
              src={countryFlagsFromLocations(country.iso2)}
              alt={`Flag for ${country.country}`}
            />

            <p className="UserProfileSpotName">{country.country}</p>
          </small>
        ))}
      </div>
    </div>
  );
};

const countryFlagsFromLocations = (iso2) => {
  return `https://storage.googleapis.com/brea-srv/country-flags-srv/${iso2}.png`;
};

export default ProfileContainer;
