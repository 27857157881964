import "../css/AppLandingPage.css";
import React, { useEffect } from "react";
import apple from "../assets/images/apple store.png";
import google from "../assets/images/play store.png";
import mocks from "../assets/images/mockups.png";
import LandingPageBanner from "../../Component/LandingPage-banner";
import WhatCanJhoticaDo from "../../Component/WhatCanJhoticaDo";
import TourComponent from "../../Component/TourComponent";

export const AppLandingPage = () => {

  return (
      <div>
          <LandingPageBanner/>
          <WhatCanJhoticaDo/>
      </div>
  );
};
export default AppLandingPage;
