import { all, select, takeEvery } from "redux-saga/effects";
import { authSagas } from "./AuthSagas";
import { profileSagas } from "./ProfileSagas";

function* logger(action) {
  if (process.env.NODE_ENV === "development") {
    const state = yield select();
    console.log("action: ", action);
    console.log("payload: ", action.payload);
    console.log("state after", state);
  }
}

export default function* combinedSaga() {
  yield all([takeEvery("*", logger), authSagas(), profileSagas()]);
}
