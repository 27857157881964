import { put } from "redux-saga/effects";

export const catchEmpties = (data) => {
  let error_message = "";
  for (const key in data) {
    const val = data[key];
    if (val == null) {
      error_message += "\r\n" + key + " can not be empty";
    }
  }
  return error_message;
};

export function sagaError(
  actionType: string,
  error: { message: string } | Error
) {
  return put({ type: actionType, payload: error.message });
}

export function sagaSuccess(actionType: string, payload: any) {
  return put({ type: actionType, payload });
}
