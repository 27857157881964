import { useEffect, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { isActionInflight } from "../../selectors/inflightActions";

const Spinner = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { actionKey, state } = props;
  useEffect(() => {
    if (isActionInflight(state, actionKey)) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [actionKey, setIsLoading, state]);

  return isLoading ? (
    <span
      className="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
  ) : null;
};

const matchStateToProps = (state) => ({
  state: state,
});

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(matchStateToProps, matchDispatchToProps)(Spinner);
