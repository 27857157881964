import { createSelector } from "reselect";

export const userDataSelector = (state) => state?.profile?.userData;

export const firebaseUserDataSelector = (state) => state?.profile?.userData;

export const userSignedInSelector = createSelector(
  userDataSelector,
  (state) => state.profile.signedIn,
  (userData, signedIn) => {
    return (
      (userData?.user_id != null || userData?.uid != null) && signedIn === true
    );
  }
);
