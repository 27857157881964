import { useSearchParams } from "react-router-dom";
import ResetPassword from "./ResetPassword";

import "../../css/Auth.css";

// TODO(Taman): decide artwork based on network speed
import forgotPasswordArtworkHiRes from "../../assets/images/forgot-password-hi-res.png";
import { NotFound } from "./NotFound";
import AuthPage from "./AuthPage";
import VerifyEmail from "./VerifyEmail";
import RecoverEmail from "./RecoverEmail";

const ActionsPage = (props) => {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  return mode === "recoverEmail" ? (
    <RecoverEmail />
  ) : mode === "verifyEmail" ? (
    <VerifyEmail />
  ) : (
    <AuthPage artwork={forgotPasswordArtworkHiRes}>
      {mode === "resetPassword" ? <ResetPassword /> : <NotFound />}
    </AuthPage>
  );
};

export default ActionsPage;
