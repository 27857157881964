import { useState } from "react";
import { validateEmail } from "../../../services/utils";

import ButtonSpinner from "../../components/Spinner";

import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";

import { isActionInflight } from "../../../selectors/inflightActions";
import { getError } from "../../../selectors/failedActions";
import { hasActionSucceeded } from "../../../selectors/successfulActions";
import ReduxErrorMessage from "../../components/ReduxErrorMessage";
import { handleUpdateEmailRequest } from "../../../redux/actions/profileActions";
import { bindActionCreators } from "redux";

const ACTION_KEY = "UPDATE_EMAIL";

type Props = {
  error: string | null,
  isLoading: boolean,
  emailChangeSuccessful: boolean,
  show: boolean,
  handleUpdateEmailRequest: (newEmail: string, password: string) => void,
  onHide: () => void,
};

const EmailModal = (props: Props) => {
  const [newEmail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationError, setValidationError] = useState(null);

  const handleSave = () => {
    props.handleUpdateEmailRequest(newEmail, password);
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="mt-3">Change Email</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100">
          <p className="text-success">
            {props.emailChangeSuccessful
              ? "Email successfully changed. Check your email to confirm the change."
              : null}
          </p>
          <form>
            <input
              autoComplete="email"
              className="brea-input settingInput user-profile"
              id="newEmail"
              name="newEmail"
              placeholder="Enter new email address"
              type="text"
              value={newEmail}
              onChange={(e) => {
                setNewEmail(e.target.value);
                if (!validateEmail(e.target.value)) {
                  setValidationError("Invalid email address format");
                } else {
                  setValidationError(null);
                }
              }}
            />

            <input
              autoComplete="current-password"
              className="brea-input settingInput user-profile"
              id="password"
              name="password"
              placeholder="Enter password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </form>

          <ReduxErrorMessage
            actionKey={ACTION_KEY}
            extraErrorMessage={validationError}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        {validateEmail(newEmail) && !!password ? (
          <Button
            disabled={props.isLoading || props.emailChangeSuccessful}
            variant="primary"
            type="submit"
            onClick={handleSave}
          >
            <ButtonSpinner actionKey={ACTION_KEY} />
            Save Changes
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isLoading: isActionInflight(state, ACTION_KEY),
  error: getError(state, ACTION_KEY),
  emailChangeSuccessful: hasActionSucceeded(state, ACTION_KEY),
});

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleUpdateEmailRequest,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(EmailModal);
