import { getValidName } from "./CountryData";
import { fetchIdTokenAndExecute } from "../../../../services/index";
import { baseUrl } from "../../../../services/utils";

export const dataStatus = {
  PUBLISHED: "PUBLISHED",
  DRAFT: "DRAFT",
  NEED_UPDATE: "NEED_UPDATE",
  NEED_REVIEW: "NEED_REVIEW",
};

export const validateInput = (str: string) => {
  const validCountries = new Set();
  const invalidCountries = new Set();
  str.split(",").forEach((country) => {
    country = country.trim();
    if (["", "and"].includes(country.toLowerCase())) {
      return;
    }
    const vNames = getValidName(country);
    if (vNames === null) {
      invalidCountries.add(country);
    } else {
      vNames.forEach((vName) => {
        validCountries.add(vName);
      });
    }
  });
  return {
    validCountries: Array.from(validCountries),
    invalidCountries: Array.from(invalidCountries),
  };
};

const getUrl = (iso2) => baseUrl + "/admin/visas/" + iso2;

export const getData = async (iso2: string) => {
  const fn = async (payload, headers, _uuid) => {
    return fetch(getUrl(iso2), {
      headers,
    });
  };
  const resp = await fetchIdTokenAndExecute(fn, null);
  if (resp.status !== 200) {
    console.error("Failed to fetch data");
    console.error(resp);
    return null;
  }
  return await resp.json();
};

export const handleSubmit = async (
  iso2: string,
  visaFreeCountries,
  onArrivalCountries,
  visaRequiredCountries,
  dependencyVisaCountries,
  eVisaCountries,
  note,
  status: dataStatus
) => {
  const data = {
    iso2,
    visaFreeCountries,
    onArrivalCountries,
    visaRequiredCountries,
    dependencyVisaCountries,
    eVisaCountries,
    note,
    status,
  };
  const fn = async (payload, headers, _uid) => {
    return fetch(getUrl(iso2), {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ visaInfo: payload }),
    });
  };

  const resp = await fetchIdTokenAndExecute(fn, data);
  if (resp.status !== 200) {
    console.error("Failed to post data");
    console.error(resp);
    return false;
  } else {
    console.log("Data posted successfully");
    return true;
  }
};
