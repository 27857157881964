import { useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { handleSignUpSubmit } from "../../../redux/actions/authActions";

import { NavLink } from "react-router-dom";
import WelcomeCard from "./WelcomeCard";
import SocialLogin from "./SocialLogin";
import Spinner from "../../components/Spinner";
import { isActionInflight } from "../../../selectors/inflightActions";
import ReduxErrorMessage from "../../components/ReduxErrorMessage";
import { handleClearAllActionsOfType } from "../../../redux/actions/actionActions";
import { hasActionSucceeded } from "../../../selectors/successfulActions";

import {
  validateEmail,
  validateFullName,
  validateFullNameMessage,
  validatePassword,
} from "../../../services/utils";

const ACTION_KEY = "SIGNUP";

const SignUp = (props: {
  signUpSuccessful: boolean,
  isLoading: boolean,
  clearAllActions: () => void,
  handleSignUpSubmit: (name: string, email: string, password: string) => void,
}) => {
  return props.signUpSuccessful ? (
    <div className="form-holder">
      <WelcomeCard welcomeText="Join" />
      <p>
        <span className="text-success">
          You have successfully signed up. Please check your email to verify.
        </span>
        <br />
        <span className="small">
          If you don't see the email, please check your spam folder.
        </span>
      </p>
    </div>
  ) : (
    <SignUpForm
      isLoading={props.isLoading}
      clearAllActions={props.clearAllActions}
      handleSignUpSubmit={props.handleSignUpSubmit}
    />
  );
};

const SignUpForm = (props: {
  isLoading: boolean,
  clearAllActions: () => void,
  handleSignUpSubmit: (name: string, email: string, password: string) => void,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const isReadyToSubmit = (): Boolean => {
    setNameError(false);
    setEmailError(false);
    setPasswordError(false);

    if (!validateFullName(name)) {
      setNameError(true);
      return false;
    }
    if (!validateEmail(email)) {
      setEmailError(true);
      return false;
    }
    if (!validatePassword(password)) {
      setPasswordError(true);
      return false;
    }

    return true;
  };

  const handleSignUp = (e, name: string, email: string, password: string) => {
    props.clearAllActions();
    e.preventDefault();
    if (!isReadyToSubmit()) {
      return;
    }
    props.handleSignUpSubmit(name, email, password);
  };

  return (
    <div className="form-holder">
      <WelcomeCard welcomeText="Join" />

      <form>
        <input
          autoComplete="name"
          className="brea-input"
          disabled={props.isLoading}
          id="name"
          name="name"
          placeholder="Enter your name"
          type="text"
          onChange={(e) => {
            setName(e.target.value);
            props.clearAllActions();
          }}
        />
        {nameError && (
          <p className="text-danger">{validateFullNameMessage()}</p>
        )}
        <input
          autoComplete="email"
          className="brea-input"
          disabled={props.isLoading}
          id="email"
          name="email"
          placeholder="Enter your email"
          type="text"
          onChange={(e) => {
            setEmail(e.target.value);
            props.clearAllActions();
          }}
        />
        {emailError && (
          <p className="text-danger">Please enter a valid email address</p>
        )}
        <input type="hidden" name="username" readOnly value={email} />

        <input
          autoComplete="new-password"
          className="brea-input"
          disabled={props.isLoading}
          id="password"
          name="password"
          placeholder="Enter password"
          type="password"
          onChange={(e) => {
            setPassword(e.target.value);
            props.clearAllActions();
          }}
        />
        <p style={{ fontSize: 12, fontWeight: 300 }}>
          By clicking Sign Up, you agree to our{" "}
          <a href="https://jhotika.com/terms-and-conditions.html">
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a href="https://jhotika.com/privacy-policy.html">Privacy Policy</a>.
        </p>
        {passwordError && (
          <p className="text-danger">
            Password must be at least 6 characters long
          </p>
        )}
        <ReduxErrorMessage actionKey={ACTION_KEY} />
        <button
          className="mt-3 btn btn-primary brea-button"
          disabled={props.isLoading}
          type="submit"
          onClick={(e) => {
            handleSignUp(e, name, email, password);
          }}
        >
          <Spinner actionKey={ACTION_KEY} /> Sign Up
        </button>
      </form>

      <hr className="line-hr mt-3" />
      {/* <p
        style={{
          textAlign: "center",
          color: "#a2a2a2",
          fontWeight: "regular",
        }}
      >
        or
      </p>

      <SocialLogin /> */}

      <p
        className="text-center pt-4"
        style={{
          fontSize: ".9rem",
        }}
      >
        Already A Member?{" "}
        <span
          style={{
            color: "#356AFC",
            textDecoration: "underline",
          }}
        >
          <NavLink to="/auth/signin"> Log In</NavLink>
        </span>
      </p>
    </div>
  );
};

const matchStateToProps = (state) => ({
  isLoading: isActionInflight(state, ACTION_KEY),
  signUpSuccessful: hasActionSucceeded(state, ACTION_KEY),
});

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleSignUpSubmit,
      clearAllActions: () => handleClearAllActionsOfType(ACTION_KEY),
    },
    dispatch
  );
};

export default connect(matchStateToProps, matchDispatchToProps)(SignUp);
