import {
  schengenVisaCountries,
  overseasFranceCountries,
  overseasBritishCountries,
  overseasDutchCountries,
} from "./overseasData";

export type CountryIso2 =
  | "AF"
  | "AL"
  | "DZ"
  | "AS"
  | "AD"
  | "AO"
  | "AI"
  | "AG"
  | "AR"
  | "AM"
  | "AW"
  | "AU"
  | "AT"
  | "AZ"
  | "BH"
  | "BD"
  | "BB"
  | "BY"
  | "BE"
  | "BZ"
  | "BJ"
  | "BM"
  | "BT"
  | "BO"
  | "BQ"
  | "BA"
  | "BW"
  | "BR"
  | "VG"
  | "BN"
  | "BG"
  | "BF"
  | "BI"
  | "CV"
  | "KH"
  | "CM"
  | "CA"
  | "KY"
  | "CF"
  | "TD"
  | "CL"
  | "CN"
  | "CX"
  | "CO"
  | "KM"
  | "CG"
  | "CD"
  | "CK"
  | "CR"
  | "HR"
  | "CU"
  | "CW"
  | "CY"
  | "CZ"
  | "CI"
  | "DK"
  | "DJ"
  | "DM"
  | "DO"
  | "EC"
  | "EG"
  | "SV"
  | "GQ"
  | "ER"
  | "EE"
  | "ET"
  | "FK"
  | "FO"
  | "FM"
  | "FJ"
  | "FI"
  | "FR"
  | "GF"
  | "PF"
  | "GA"
  | "GE"
  | "DE"
  | "GH"
  | "GI"
  | "GR"
  | "GL"
  | "GD"
  | "GP"
  | "GU"
  | "GT"
  | "GN"
  | "GW"
  | "GY"
  | "HT"
  | "HN"
  | "HK"
  | "HU"
  | "IS"
  | "IN"
  | "ID"
  | "IR"
  | "IQ"
  | "IE"
  | "IM"
  | "IL"
  | "IT"
  | "JM"
  | "JP"
  | "JE"
  | "JO"
  | "KZ"
  | "KE"
  | "KI"
  | "XK"
  | "KW"
  | "KG"
  | "LA"
  | "LV"
  | "LB"
  | "LS"
  | "LR"
  | "LY"
  | "LI"
  | "LT"
  | "LU"
  | "MO"
  | "MK"
  | "MG"
  | "MW"
  | "MY"
  | "MV"
  | "ML"
  | "MT"
  | "MH"
  | "MQ"
  | "MR"
  | "MU"
  | "YT"
  | "MX"
  | "MD"
  | "MC"
  | "MN"
  | "ME"
  | "MS"
  | "MA"
  | "MZ"
  | "MM"
  | "NA"
  | "NR"
  | "NP"
  | "NL"
  | "NC"
  | "NZ"
  | "NI"
  | "NE"
  | "NG"
  | "NU"
  | "NF"
  | "KP"
  | "MP"
  | "NO"
  | "OM"
  | "PK"
  | "PW"
  | "PA"
  | "PG"
  | "PY"
  | "PE"
  | "PH"
  | "PN"
  | "PL"
  | "PT"
  | "PR"
  | "QA"
  | "RO"
  | "RU"
  | "RW"
  | "BL"
  | "SH"
  | "KN"
  | "LC"
  | "MF"
  | "PM"
  | "VC"
  | "WS"
  | "SM"
  | "ST"
  | "SA"
  | "SN"
  | "RS"
  | "SC"
  | "SL"
  | "SG"
  | "SX"
  | "SK"
  | "SI"
  | "SB"
  | "SO"
  | "ZA"
  | "KR"
  | "SS"
  | "ES"
  | "LK"
  | "SD"
  | "SR"
  | "XR"
  | "SZ"
  | "SE"
  | "CH"
  | "SY"
  | "TW"
  | "TJ"
  | "TZ"
  | "TH"
  | "BS"
  | "GM"
  | "TL"
  | "TG"
  | "TO"
  | "TT"
  | "TN"
  | "TR"
  | "TM"
  | "TC"
  | "TV"
  | "VI"
  | "UG"
  | "UA"
  | "AE"
  | "GB"
  | "US"
  | "UY"
  | "UZ"
  | "VU"
  | "VA"
  | "VE"
  | "VN"
  | "WF"
  | "YE"
  | "ZM"
  | "ZW"
  | "RE";

export type shortCountry = {
  fullName: string,
  iso2: CountryIso2,
  completeName?: string,
};
export const allCountries: {
  CountryIso2: shortCountry,
} = {
  AF: {
    fullName: "Afghanistan",
    iso2: "AF",
  },
  AL: {
    fullName: "Albania",
    iso2: "AL",
  },
  DZ: {
    fullName: "Algeria",
    iso2: "DZ",
  },
  AS: {
    fullName: "American Samoa",
    iso2: "AS",
  },
  AD: {
    fullName: "Andorra",
    iso2: "AD",
  },
  AO: {
    fullName: "Angola",
    iso2: "AO",
  },
  AI: {
    fullName: "Anguilla",
    iso2: "AI",
  },
  AG: {
    fullName: "Antigua and Barbuda",
    iso2: "AG",
  },
  AR: {
    fullName: "Argentina",
    iso2: "AR",
  },
  AM: {
    fullName: "Armenia",
    iso2: "AM",
  },
  AW: {
    fullName: "Aruba",
    iso2: "AW",
  },
  AU: {
    fullName: "Australia",
    iso2: "AU",
  },
  AT: {
    fullName: "Austria",
    iso2: "AT",
  },
  AZ: {
    fullName: "Azerbaijan",
    iso2: "AZ",
  },
  BH: {
    fullName: "Bahrain",
    iso2: "BH",
  },
  BD: {
    fullName: "Bangladesh",
    iso2: "BD",
  },
  BB: {
    fullName: "Barbados",
    iso2: "BB",
  },
  BY: {
    fullName: "Belarus",
    iso2: "BY",
  },
  BE: {
    fullName: "Belgium",
    iso2: "BE",
  },
  BZ: {
    fullName: "Belize",
    iso2: "BZ",
  },
  BJ: {
    fullName: "Benin",
    iso2: "BJ",
  },
  BM: {
    fullName: "Bermuda",
    iso2: "BM",
  },
  BT: {
    fullName: "Bhutan",
    iso2: "BT",
  },
  BO: {
    fullName: "Bolivia",
    iso2: "BO",
  },
  BQ: {
    fullName: "Caribbean Netherlands",
    iso2: "BQ",
    completeName: "Bonaire, Sint Eustatius and Saba",
  },
  BA: {
    fullName: "Bosnia and Herzegovina",
    iso2: "BA",
  },
  BW: {
    fullName: "Botswana",
    iso2: "BW",
  },
  BR: {
    fullName: "Brazil",
    iso2: "BR",
  },
  VG: {
    fullName: "British Virgin Islands",
    iso2: "VG",
  },
  BN: {
    fullName: "Brunei",
    iso2: "BN",
  },
  BG: {
    fullName: "Bulgaria",
    iso2: "BG",
  },
  BF: {
    fullName: "Burkina Faso",
    iso2: "BF",
  },
  BI: {
    fullName: "Burundi",
    iso2: "BI",
  },
  CV: {
    fullName: "Cabo Verde",
    iso2: "CV",
  },
  KH: {
    fullName: "Cambodia",
    iso2: "KH",
  },
  CM: {
    fullName: "Cameroon",
    iso2: "CM",
  },
  CA: {
    fullName: "Canada",
    iso2: "CA",
  },
  KY: {
    fullName: "Cayman Islands",
    iso2: "KY",
  },
  CF: {
    fullName: "Central African Republic",
    iso2: "CF",
  },
  TD: {
    fullName: "Chad",
    iso2: "TD",
  },
  CL: {
    fullName: "Chile",
    iso2: "CL",
  },
  CN: {
    fullName: "China",
    iso2: "CN",
  },
  CX: {
    fullName: "Christmas Island",
    iso2: "CX",
  },
  CO: {
    fullName: "Colombia",
    iso2: "CO",
  },
  KM: {
    fullName: "Comoros",
    iso2: "KM",
  },
  CG: {
    fullName: "Congo (Brazzaville)",
    iso2: "CG",
  },
  CD: {
    fullName: "Congo (Kinshasa)",
    iso2: "CD",
  },
  CK: {
    fullName: "Cook Islands",
    iso2: "CK",
  },
  CR: {
    fullName: "Costa Rica",
    iso2: "CR",
  },
  HR: {
    fullName: "Croatia",
    iso2: "HR",
  },
  CU: {
    fullName: "Cuba",
    iso2: "CU",
  },
  CW: {
    fullName: "Curaçao",
    iso2: "CW",
  },
  CY: {
    fullName: "Cyprus",
    iso2: "CY",
  },
  CZ: {
    fullName: "Czechia / Czech Republic",
    iso2: "CZ",
  },
  CI: {
    fullName: "Côte d'Ivoire",
    iso2: "CI",
  },
  DK: {
    fullName: "Denmark",
    iso2: "DK",
  },
  DJ: {
    fullName: "Djibouti",
    iso2: "DJ",
  },
  DM: {
    fullName: "Dominica",
    iso2: "DM",
  },
  DO: {
    fullName: "Dominican Republic",
    iso2: "DO",
  },
  EC: {
    fullName: "Ecuador",
    iso2: "EC",
  },
  EG: {
    fullName: "Egypt",
    iso2: "EG",
  },
  SV: {
    fullName: "El Salvador",
    iso2: "SV",
  },
  GQ: {
    fullName: "Equatorial Guinea",
    iso2: "GQ",
  },
  ER: {
    fullName: "Eritrea",
    iso2: "ER",
  },
  EE: {
    fullName: "Estonia",
    iso2: "EE",
  },
  ET: {
    fullName: "Ethiopia",
    iso2: "ET",
  },
  FK: {
    fullName: "Falkland Islands",
    iso2: "FK",
    completeName: "Falkland Islands (Islina Malvinas)",
  },
  FO: {
    fullName: "Faroe Islands",
    iso2: "FO",
  },
  FM: {
    fullName: "Micronesia",
    iso2: "FM",
    completeName: "Federated States of Micronesia",
  },
  FJ: {
    fullName: "Fiji",
    iso2: "FJ",
  },
  FI: {
    fullName: "Finland",
    iso2: "FI",
  },
  FR: {
    fullName: "France",
    iso2: "FR",
  },
  GF: {
    fullName: "French Guiana",
    iso2: "GF",
  },
  PF: {
    fullName: "French Polynesia",
    iso2: "PF",
  },
  GA: {
    fullName: "Gabon",
    iso2: "GA",
  },
  GE: {
    fullName: "Georgia",
    iso2: "GE",
  },
  DE: {
    fullName: "Germany",
    iso2: "DE",
  },
  GH: {
    fullName: "Ghana",
    iso2: "GH",
  },
  GI: {
    fullName: "Gibraltar",
    iso2: "GI",
  },
  GR: {
    fullName: "Greece",
    iso2: "GR",
  },
  GL: {
    fullName: "Greenland",
    iso2: "GL",
  },
  GD: {
    fullName: "Grenada",
    iso2: "GD",
  },
  GP: {
    fullName: "Guadeloupe",
    iso2: "GP",
  },
  GU: {
    fullName: "Guam",
    iso2: "GU",
  },
  GT: {
    fullName: "Guatemala",
    iso2: "GT",
  },
  GN: {
    fullName: "Guinea",
    iso2: "GN",
  },
  GW: {
    fullName: "Guinea-Bissau",
    iso2: "GW",
  },
  GY: {
    fullName: "Guyana",
    iso2: "GY",
  },
  HT: {
    fullName: "Haiti",
    iso2: "HT",
  },
  HN: {
    fullName: "Honduras",
    iso2: "HN",
  },
  HK: {
    fullName: "Hong Kong",
    iso2: "HK",
  },
  HU: {
    fullName: "Hungary",
    iso2: "HU",
  },
  IS: {
    fullName: "Iceland",
    iso2: "IS",
  },
  IN: {
    fullName: "India",
    iso2: "IN",
  },
  ID: {
    fullName: "Indonesia",
    iso2: "ID",
  },
  IR: {
    fullName: "Iran",
    iso2: "IR",
  },
  IQ: {
    fullName: "Iraq",
    iso2: "IQ",
  },
  IE: {
    fullName: "Ireland",
    iso2: "IE",
  },
  IM: {
    fullName: "Isle Of Man",
    iso2: "IM",
  },
  IL: {
    fullName: "Israel",
    iso2: "IL",
  },
  IT: {
    fullName: "Italy",
    iso2: "IT",
  },
  JM: {
    fullName: "Jamaica",
    iso2: "JM",
  },
  JP: {
    fullName: "Japan",
    iso2: "JP",
  },
  JE: {
    fullName: "Jersey",
    iso2: "JE",
  },
  JO: {
    fullName: "Jordan",
    iso2: "JO",
  },
  KZ: {
    fullName: "Kazakhstan",
    iso2: "KZ",
  },
  KE: {
    fullName: "Kenya",
    iso2: "KE",
  },
  KI: {
    fullName: "Kiribati",
    iso2: "KI",
  },
  XK: {
    fullName: "Kosovo",
    iso2: "XK",
  },
  KW: {
    fullName: "Kuwait",
    iso2: "KW",
  },
  KG: {
    fullName: "Kyrgyzstan",
    iso2: "KG",
  },
  LA: {
    fullName: "Laos",
    iso2: "LA",
  },
  LV: {
    fullName: "Latvia",
    iso2: "LV",
  },
  LB: {
    fullName: "Lebanon",
    iso2: "LB",
  },
  LS: {
    fullName: "Lesotho",
    iso2: "LS",
  },
  LR: {
    fullName: "Liberia",
    iso2: "LR",
  },
  LY: {
    fullName: "Libya",
    iso2: "LY",
  },
  LI: {
    fullName: "Liechtenstein",
    iso2: "LI",
  },
  LT: {
    fullName: "Lithuania",
    iso2: "LT",
  },
  LU: {
    fullName: "Luxembourg",
    iso2: "LU",
  },
  MO: {
    fullName: "Macau",
    iso2: "MO",
  },
  MK: {
    fullName: "Macedonia",
    iso2: "MK",
  },
  MG: {
    fullName: "Madagascar",
    iso2: "MG",
  },
  MW: {
    fullName: "Malawi",
    iso2: "MW",
  },
  MY: {
    fullName: "Malaysia",
    iso2: "MY",
  },
  MV: {
    fullName: "Maldives",
    iso2: "MV",
  },
  ML: {
    fullName: "Mali",
    iso2: "ML",
  },
  MT: {
    fullName: "Malta",
    iso2: "MT",
  },
  MH: {
    fullName: "Marshall Islands",
    iso2: "MH",
  },
  MQ: {
    fullName: "Martinique",
    iso2: "MQ",
  },
  MR: {
    fullName: "Mauritania",
    iso2: "MR",
  },
  MU: {
    fullName: "Mauritius",
    iso2: "MU",
  },
  YT: {
    fullName: "Mayotte",
    iso2: "YT",
  },
  MX: {
    fullName: "Mexico",
    iso2: "MX",
  },
  MD: {
    fullName: "Moldova",
    iso2: "MD",
  },
  MC: {
    fullName: "Monaco",
    iso2: "MC",
  },
  MN: {
    fullName: "Mongolia",
    iso2: "MN",
  },
  ME: {
    fullName: "Montenegro",
    iso2: "ME",
  },
  MS: {
    fullName: "Montserrat",
    iso2: "MS",
  },
  MA: {
    fullName: "Morocco",
    iso2: "MA",
  },
  MZ: {
    fullName: "Mozambique",
    iso2: "MZ",
  },
  MM: {
    fullName: "Myanmar",
    iso2: "MM",
  },
  NA: {
    fullName: "Namibia",
    iso2: "NA",
  },
  NR: {
    fullName: "Nauru",
    iso2: "NR",
  },
  NP: {
    fullName: "Nepal",
    iso2: "NP",
  },
  NL: {
    fullName: "Netherlands",
    iso2: "NL",
  },
  NC: {
    fullName: "New Caledonia",
    iso2: "NC",
  },
  NZ: {
    fullName: "New Zealand",
    iso2: "NZ",
  },
  NI: {
    fullName: "Nicaragua",
    iso2: "NI",
  },
  NE: {
    fullName: "Niger",
    iso2: "NE",
  },
  NG: {
    fullName: "Nigeria",
    iso2: "NG",
  },
  NU: {
    fullName: "Niue",
    iso2: "NU",
  },
  NF: {
    fullName: "Norfolk Island",
    iso2: "NF",
  },
  KP: {
    fullName: "North Korea",
    iso2: "KP",
  },
  MP: {
    fullName: "Northern Mariana Islands",
    iso2: "MP",
  },
  NO: {
    fullName: "Norway",
    iso2: "NO",
  },
  OM: {
    fullName: "Oman",
    iso2: "OM",
  },
  PK: {
    fullName: "Pakistan",
    iso2: "PK",
  },
  PW: {
    fullName: "Palau",
    iso2: "PW",
  },
  PA: {
    fullName: "Panama",
    iso2: "PA",
  },
  PG: {
    fullName: "Papua New Guinea",
    iso2: "PG",
  },
  PY: {
    fullName: "Paraguay",
    iso2: "PY",
  },
  PE: {
    fullName: "Peru",
    iso2: "PE",
  },
  PH: {
    fullName: "Philippines",
    iso2: "PH",
  },
  PN: {
    fullName: "Pitcairn Islands",
    iso2: "PN",
  },
  PL: {
    fullName: "Poland",
    iso2: "PL",
  },
  PT: {
    fullName: "Portugal",
    iso2: "PT",
  },
  PR: {
    fullName: "Puerto Rico",
    iso2: "PR",
  },
  QA: {
    fullName: "Qatar",
    iso2: "QA",
  },
  RO: {
    fullName: "Romania",
    iso2: "RO",
  },
  RU: {
    fullName: "Russia",
    iso2: "RU",
  },
  RW: {
    fullName: "Rwanda",
    iso2: "RW",
  },
  BL: {
    fullName: "Saint Barthelemy",
    iso2: "BL",
  },
  SH: {
    fullName: "Saint Helena",
    iso2: "SH",
    completeName: "Saint Helena, Ascension, and Tristan da Cunha",
  },
  KN: {
    fullName: "Saint Kitts and Nevis",
    iso2: "KN",
  },
  LC: {
    fullName: "Saint Lucia",
    iso2: "LC",
  },
  MF: {
    fullName: "Saint Martin",
    iso2: "MF",
  },
  PM: {
    fullName: "Saint Pierre and Miquelon",
    iso2: "PM",
  },
  VC: {
    fullName: "Saint Vincent",
    iso2: "VC",
    completeName: "Saint Vincent and the Grenadines",
  },
  WS: {
    fullName: "Samoa",
    iso2: "WS",
  },
  SM: {
    fullName: "San Marino",
    iso2: "SM",
  },
  ST: {
    fullName: "Sao Tome and Principe",
    iso2: "ST",
  },
  SA: {
    fullName: "Saudi Arabia",
    iso2: "SA",
  },
  SN: {
    fullName: "Senegal",
    iso2: "SN",
  },
  RS: {
    fullName: "Serbia",
    iso2: "RS",
  },
  SC: {
    fullName: "Seychelles",
    iso2: "SC",
  },
  SL: {
    fullName: "Sierra Leone",
    iso2: "SL",
  },
  SG: {
    fullName: "Singapore",
    iso2: "SG",
  },
  SX: {
    fullName: "Sint Maarten",
    iso2: "SX",
  },
  SK: {
    fullName: "Slovakia",
    iso2: "SK",
  },
  SI: {
    fullName: "Slovenia",
    iso2: "SI",
  },
  SB: {
    fullName: "Solomon Islands",
    iso2: "SB",
  },
  SO: {
    fullName: "Somalia",
    iso2: "SO",
  },
  ZA: {
    fullName: "South Africa",
    iso2: "ZA",
  },
  KR: {
    fullName: "South Korea",
    iso2: "KR",
  },
  SS: {
    fullName: "South Sudan",
    iso2: "SS",
  },
  ES: {
    fullName: "Spain",
    iso2: "ES",
  },
  LK: {
    fullName: "Sri Lanka",
    iso2: "LK",
  },
  SD: {
    fullName: "Sudan",
    iso2: "SD",
  },
  SR: {
    fullName: "Suriname",
    iso2: "SR",
  },
  XR: {
    fullName: "Svalbard",
    iso2: "XR",
  },
  SZ: {
    fullName: "Swaziland",
    iso2: "SZ",
  },
  SE: {
    fullName: "Sweden",
    iso2: "SE",
  },
  CH: {
    fullName: "Switzerland",
    iso2: "CH",
  },
  SY: {
    fullName: "Syria",
    iso2: "SY",
  },
  TW: {
    fullName: "Taiwan",
    iso2: "TW",
  },
  TJ: {
    fullName: "Tajikistan",
    iso2: "TJ",
  },
  TZ: {
    fullName: "Tanzania",
    iso2: "TZ",
  },
  TH: {
    fullName: "Thailand",
    iso2: "TH",
  },
  BS: {
    fullName: "The Bahamas",
    iso2: "BS",
  },
  GM: {
    fullName: "The Gambia",
    iso2: "GM",
  },
  TL: {
    fullName: "Timor-Leste",
    iso2: "TL",
  },
  TG: {
    fullName: "Togo",
    iso2: "TG",
  },
  TO: {
    fullName: "Tonga",
    iso2: "TO",
  },
  TT: {
    fullName: "Trinidad and Tobago",
    iso2: "TT",
  },
  TN: {
    fullName: "Tunisia",
    iso2: "TN",
  },
  TR: {
    fullName: "Turkey",
    iso2: "TR",
  },
  TM: {
    fullName: "Turkmenistan",
    iso2: "TM",
  },
  TC: {
    fullName: "Turks and Caicos Islands",
    iso2: "TC",
  },
  TV: {
    fullName: "Tuvalu",
    iso2: "TV",
  },
  VI: {
    fullName: "U.S. Virgin Islands",
    iso2: "VI",
  },
  UG: {
    fullName: "Uganda",
    iso2: "UG",
  },
  UA: {
    fullName: "Ukraine",
    iso2: "UA",
  },
  AE: {
    fullName: "United Arab Emirates",
    iso2: "AE",
  },
  GB: {
    fullName: "United Kingdom",
    iso2: "GB",
  },
  US: {
    fullName: "United States",
    iso2: "US",
  },
  UY: {
    fullName: "Uruguay",
    iso2: "UY",
  },
  UZ: {
    fullName: "Uzbekistan",
    iso2: "UZ",
  },
  VU: {
    fullName: "Vanuatu",
    iso2: "VU",
  },
  VA: {
    fullName: "Vatican City",
    iso2: "VA",
  },
  VE: {
    fullName: "Venezuela",
    iso2: "VE",
  },
  VN: {
    fullName: "Vietnam",
    iso2: "VN",
  },
  WF: {
    fullName: "Wallis and Futuna",
    iso2: "WF",
  },
  YE: {
    fullName: "Yemen",
    iso2: "YE",
  },
  ZM: {
    fullName: "Zambia",
    iso2: "ZM",
  },
  ZW: {
    fullName: "Zimbabwe",
    iso2: "ZW",
  },
  RE: {
    fullName: "Réunion",
    iso2: "RE",
  },
};

export const getFullNameFromIso2 = (iso2: CountryIso2): string | null => {
  const country = allCountries[iso2];
  if (!country) {
    return null;
  }
  return country.fullName;
};

export const mapCountryNames = (rawFullname: string): string => {
  const fullname = rawFullname.toLowerCase().replace(/\./g, "");
  // USA
  if (["us", "usa"].includes(fullname)) {
    return getFullNameFromIso2("US");
  }
  // UK
  if (fullname === "uk") {
    return getFullNameFromIso2("GB");
  }
  // UAE
  if (["uae", "emirates"].includes(fullname)) {
    return getFullNameFromIso2("AE");
  }
  // Czechia
  if (fullname.indexOf("czech") !== -1) {
    return getFullNameFromIso2("CZ");
  }

  // Ivoire
  if (fullname.indexOf("ivoire") !== -1) {
    return getFullNameFromIso2("CI");
  }

  if (fullname.indexOf("brazzaville") !== -1) {
    return getFullNameFromIso2("CG");
  }

  if (fullname.indexOf("kinshasa") !== -1) {
    return getFullNameFromIso2("CD");
  }

  return rawFullname;
};

export const getValidName = (rawFullname: string): Array<string> => {
  if (rawFullname.toLowerCase() === "schengen") {
    return schengenVisaCountries;
  }
  if (rawFullname.toLowerCase() === "froverseas") {
    return overseasFranceCountries;
  }
  if (rawFullname.toLowerCase() === "broverseas") {
    return overseasBritishCountries;
  }
  if (rawFullname.toLowerCase() === "duoverseas") {
    return overseasDutchCountries;
  }
  const validNames = Object.values(allCountries).filter((country) =>
    areCountryNamesEqual(country.fullName, rawFullname)
  );
  if (validNames.length === 0) {
    return null;
  }
  return [validNames[0].fullName];
};

const areCountryNamesEqual = (a: string, b: string): number => {
  const aName = sanitizeAndNormalizeCountryName(a);
  const bName = sanitizeAndNormalizeCountryName(b);
  return aName === bName || aName + "s" === bName || aName === bName + "s";
};

const sanitizeAndNormalizeCountryName = (name: string): string | null => {
  const mappedName = mapCountryNames(name);
  if (!mappedName) {
    return null;
  }
  const normalizedName = normalizeAccents(mappedName);
  return removeLeadingThe(normalizedName).toLowerCase().trim();
};

const removeLeadingThe = (str: string): string => {
  return str.replace(/^the /i, "").trim();
};

const normalizeAccents = (str: string): string => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const getIso2FromFullName = (fullName: string): CountryIso2 | null => {
  const country = getShortCountryFromFullname(fullName);
  if (!country) {
    return null;
  }
  return country.iso2;
};

export const getShortCountryFromFullname = (
  fullName: string
): shortCountry | null => {
  const shortCountries = Object.values(allCountries).filter(
    (country) => country.fullName === fullName
  );
  if (shortCountries.length === 0) {
    return null;
  }
  return shortCountries[0];
};

export type Destination = {
  countryIso2: CountryIso2,
  onArrivalVisaSources: Array<CountryIso2>,
  freeVisaSources: Array<CountryIso2>,
  dependantVisaSources: Array<CountryIso2>,
};
