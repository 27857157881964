import React, { useState } from "react";
import AvatarEditor from "react-avatar-editor";

import Form from "react-bootstrap/Form";

const BreaAvatarEditor = (props: {
  editorRef: React.MutableRefObject,
  newImageUrl: string,
  onSave: () => {},
}): React.Component => {
  const [scale, setScale] = useState(1);
  return (
    <>
      <AvatarEditor
        ref={props.editorRef}
        image={props.newImageUrl}
        width={250}
        height={250}
        borderRadius={250}
        // border={50}
        color={[255, 255, 255, 0.6]} // RGBA
        scale={scale}
        rotate={0}
      />
      <Form.Range
        min={1}
        max={2}
        step={0.01}
        onChange={(e) => {
          setScale(parseFloat(e.target.value) ?? 1);
        }}
        value={scale}
      />
    </>
  );
};

export default BreaAvatarEditor;
