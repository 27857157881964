import { useState } from "react";

import ButtonSpinner from "../../components/Spinner";

import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";

import { isActionInflight } from "../../../selectors/inflightActions";
import { getError } from "../../../selectors/failedActions";
import ReduxErrorMessage from "../../components/ReduxErrorMessage";
import { userDataSelector } from "../../../selectors/user";
import { handleDeleteAccount } from "../../../redux/actions/profileActions";
import { bindActionCreators } from "redux";

const ACTION_KEY = "DELETE_ACCOUNT";

type Props = {
  error: string | null,
  isLoading: boolean,
  emailChangeSuccessful: boolean,
  show: boolean,
  handleDeleteAccount: (newEmail: string, password: string) => void,
  onHide: () => void,
};

const DeleteAccountModal = (props: Props) => {
  const [password, setPassword] = useState("");

  const handleSave = () => {
    props.handleDeleteAccount(props.viewerData?.email || "", password);
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="mt-3">Delete Your Jhotika Account</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100">
          <p style={{ textAlign: "center" }}>
            This action will permanently delete your account and all of your
            generated contents.{" "}
            <a href="https://jhotika.com/deletion-policy">Learn more</a>
          </p>
          <form>
            <input
              autoComplete="current-password"
              className="brea-input settingInput user-profile"
              id="password"
              name="password"
              placeholder="Enter password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </form>

          <ReduxErrorMessage actionKey={ACTION_KEY} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        {!!password ? (
          <Button
            disabled={props.isLoading || props.emailChangeSuccessful}
            type="submit"
            onClick={handleSave}
            style={{ backgroundColor: "#ff5a0e", borderColor: "#ff5a0e" }}
          >
            <ButtonSpinner actionKey={ACTION_KEY} />
            Delete My Account
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isLoading: isActionInflight(state, ACTION_KEY),
  error: getError(state, ACTION_KEY),
  viewerData: userDataSelector(state),
});

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleDeleteAccount,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(DeleteAccountModal);
