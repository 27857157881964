import { useEffect, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { handleUpdateUsername } from "../../../redux/actions/profileActions";
import { handleClearAllActionsOfType } from "../../../redux/actions/actionActions";
import { isActionInflight } from "../../../selectors/inflightActions";
import { getError } from "../../../selectors/failedActions";
import { hasActionSucceeded } from "../../../selectors/successfulActions";

import { Modal, Button } from "react-bootstrap";
import Spinner from "../../components/Spinner";
import ReduxErrorMessage from "../../components/ReduxErrorMessage";

const ACTION_KEY = "UPDATE_USERNAME";
const MAX_USERNAME_LENGTH = 20;
const MIN_USERNAME_LENGTH = 6;

const UsernameModal = (props) => {
  const currentUsername = props?.userData?.username ?? "";
  const [username, setUsername] = useState(currentUsername);
  const [validationError, setValidationError] = useState(null);
  const { error, isSuccessful, clearAllActions } = props;

  const invalidErrorMessage = `Invalid username.  Username should have between ${MIN_USERNAME_LENGTH} and ${MAX_USERNAME_LENGTH} characters from a-z, A-Z, 0-9`;

  const handleSave = () => {
    if (!validateUsername(username) || username.length < MIN_USERNAME_LENGTH) {
      setValidationError(invalidErrorMessage);
      return;
    }
    props.updateUsername(username.trim());
  };

  const handleClose = () => {
    clearAllActions();
    setUsername(props?.userData?.username);
    props.onHide();
  };

  useEffect(() => {
    if (username !== currentUsername) {
      clearAllActions();
    }
  }, [username, currentUsername, clearAllActions]);

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton={!props.isLoading}>
        <Modal.Title>Edit Username</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isSuccessful && <p className="text-success">Username updated</p>}
        <input
          autoComplete="username"
          className="brea-input settingInput user-profile"
          id="newUsername"
          name="newUsername"
          placeholder="Enter new username"
          type="text"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
            if (!validateUsername(e.target.value)) {
              setValidationError(invalidErrorMessage);
            } else {
              setValidationError(null);
            }
          }}
        />

        <ReduxErrorMessage
          actionKey={ACTION_KEY}
          extraErrorMessage={validationError}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={props.isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
        {username.trim() !== currentUsername.trim() ? (
          <Button
            disabled={error || props.isLoading}
            variant="primary"
            onClick={handleSave}
          >
            <Spinner actionKey={ACTION_KEY} />
            Save Changes
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

const validateUsername = (rawUsername: string): boolean => {
  const username = rawUsername.trim();
  return (
    username &&
    String(username)
      .toLowerCase()
      .match(/^[a-zA-Z0-9]+$/) != null &&
    username.length <= MAX_USERNAME_LENGTH
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateUsername: handleUpdateUsername,
      clearAllActions: () => handleClearAllActionsOfType(ACTION_KEY),
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: isActionInflight(state, ACTION_KEY),
    error: getError(state, ACTION_KEY),
    isSuccessful: hasActionSucceeded(state, ACTION_KEY),
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(UsernameModal);
